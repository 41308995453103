<template>
  <v-container>
    <v-card class="mb-6">
      <v-card-title class="subtitle-1 font-weight-regular">
        {{ home?.address?.address }}
        <v-chip class="status-badge text-capitalize ml-2 font-weight-medium" color="primary">
          {{ getFormattedCheckInStatus }}
        </v-chip>
      </v-card-title>

      <v-card-text>
        Go over all the sections of your home check-in. Each section needs to
        have at least one photo.
      </v-card-text>
    </v-card>
    <template>
      <v-stepper v-model="currentStep" vertical>
        <template v-for="(step, index) in steps">
          <template v-if="defaultSteps.includes(step.key)">
            <v-stepper-step
              class="custom-step-header"
              :key="step.key"
              :step="index + 1"
              editable
            >
              <div class="custon-avatar">
                <v-img width="40" :src="step?.placeholderIcon" contain></v-img>
                <v-icon
                  v-if="currentStep === index + 1"
                  class="edit-icon"
                  color="primary"
                  >$vuetify.icons.pencil_circle</v-icon
                >
                <v-icon v-if="step.isInvalid" class="edit-icon" color="danger"
                  >$vuetify.icons.pages_about</v-icon
                >
                <v-icon v-if="step.isValid" class="edit-icon" color="success"
                  >$vuetify.icons.check_circle</v-icon
                >
              </div>
              {{ step.title }}
            </v-stepper-step>

            <v-stepper-content
              height="auto"
              :key="'content' + step.key"
              :step="index + 1"
            >
              <template v-if="step?.gate">
                <v-checkbox
                  :label="step?.gate?.question"
                  v-model="step.gate.value"
                  :disabled="getFormattedCheckInStatus !== 'In Progress'"
                  @change="onCheckBoxChange($event, step.key)"
                ></v-checkbox>
              </template>
              <template v-if="!step.gate || (step?.gate && step.gate.value)">
                <p>
                  {{ step.categoryInstructions }}
                  <a @click="openPreviewDialog(step.sampleImage, step.title)"
                    >Click here to view a sample.
                  </a>
                </p>
                <div class="images-wrapper">
                  <input
                    type="file"
                    hidden
                    :ref="`fileRef${step.key}`"
                    accept="image/*"
                    maxlength="2"
                    :max="2"
                    @change="
                      onImageChange($event, step.photoPromptInstructions)
                    "
                  />
                  <button
                    v-for="(img, imgIndex) in propertyImages[step.key]"
                    class="property-img-button d-flex align-center justify-center"
                    :class="{
                      'opacity cursor-auto':
                        imgIndex !== 0 &&
                        !propertyImages[step.key][imgIndex - 1].photoUrl,
                      'cursor-pointer': img.photoUrl,
                    }"
                    :disabled="loadingImages.includes(img?.id)"
                    :key="img.id"
                    @click="img.photoUrl && openPreviewDialog(img.photoUrl)"
                  >
                    <v-overlay
                      class="img-overlay"
                      :value="loadingImages.includes(img?.id)"
                    >
                      <v-progress-circular
                        indeterminate
                        size="34"
                      ></v-progress-circular>
                    </v-overlay>
                    <v-img
                      cover
                      height="100%"
                      v-if="img.photoUrl"
                      :src="img.photoUrl"
                    ></v-img>
                    <v-img
                      v-else
                      class="placeholder-img"
                      width="80"
                      max-width="80"
                      :src="step?.placeholderIcon"
                    ></v-img>
                    <v-badge
                      class="image-count"
                      color="info"
                      :content="imgIndex + 1"
                      inline
                    ></v-badge>
                    <template
                      v-if="getFormattedCheckInStatus === 'In Progress'"
                    >
                      <v-btn
                        v-if="
                          imgIndex === 0 ||
                          (imgIndex !== 0 &&
                            propertyImages[step.key][imgIndex - 1].photoUrl)
                        "
                        class="image-action-icon retake-icon"
                        icon
                        rounded
                        @click.stop="onChangeImg(step.key, imgIndex)"
                      >
                        <v-icon>$vuetify.icons.actions_retake_photo</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="img.photoUrl"
                        class="image-action-icon delete-icon"
                        icon
                        rounded
                        @click.stop="openDeleteDialog(step.key, imgIndex)"
                      >
                        <v-icon color="accent"
                          >$vuetify.icons.actions_delete</v-icon
                        >
                      </v-btn>
                      <v-btn
                        v-if="img.photoUrl && !img.synced"
                        class="image-action-icon cloud-icon"
                        icon
                        rounded
                        @click.stop="onSyncImg(step.key, img, imgIndex)"
                      >
                        <v-icon color="accent"
                          >$vuetify.icons.global_cloud_off</v-icon
                        >
                      </v-btn>
                      <v-icon
                        v-if="img.synced"
                        class="image-action-icon cloud-check"
                        color="primary"
                        >$vuetify.icons.global_cloud_check</v-icon
                      >
                    </template>
                  </button>
                </div>
              </template>
            </v-stepper-content>
          </template>
        </template>
      </v-stepper>
      <ImageViewerDialog
        :isOpen="dialog"
        :title="modalTitle"
        :imgUrl="previewImg"
        @onClose="dialog = false"
      />
    </template>
    <ConfirmationDialog
      :isOpen="showDeleteDialog"
      title="Are you sure you want to delete this photo?"
      bodyText="This is a permanent action and you will not be able to retrieve your
          photo."
      cancelButton="Cancel"
      confirmButton="Continue"
      @onCancel="showDeleteDialog = false"
      @onConfirm="onDeleteImage()"
    />
    <ConfirmationDialog
      :isOpen="showSyncErrorDialog"
      title="Error syncing photo to cloud"
      bodyText="Don't worry. All your data is cached on your browser. You will get a chance to sync your pictures before submitting your checkin."
      confirmButton="Continue"
      @onConfirm="showSyncErrorDialog = false"
    />
    <v-dialog v-model="confirmImgDialog.show" persistent max-width="345">
      <v-card>
        <div class="img-confirm-title">
          <v-img :src="confirmImgDialog.imgPath" max-height="250"></v-img>
          <v-btn
            class="img-confirm-retake"
            icon
            rounded
            @click="onChangeImg(activeEditImgKey, activeEditImgIndex)"
          >
            <v-icon>$vuetify.icons.actions_retake_photo</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pt-4 pb-0">
          {{ confirmImgDialog.description }}
        </v-card-text>
        <v-card-actions class="shrink">
          <v-spacer />
          <v-btn
            text
            color="primary"
            :disabled="isSyncingPhoto"
            @click="
              uploadPhoto(
                confirmImgDialog.fileName,
                confirmImgDialog.imgPath,
                confirmImgDialog.exif
              )
            "
          >
            Continue
          </v-btn>
          <v-btn
            text
            color="grey darken-2"
            :disabled="isSyncingPhoto"
            @click="onChangeImg(activeEditImgKey, activeEditImgIndex)"
          >
            Take Another
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer app outlined>
      <div class="check-in-footer d-flex justify-space-between align-center">
        <a
          v-if="getFormattedCheckInStatus === 'In Progress'"
          class="body-2 text-uppercase text-decoration-underline"
        >
          Choose which companies can buy you info
        </a>
        <v-spacer v-else></v-spacer>
        <v-btn
          color="primary"
          class="text-capitalize button"
          :to="{
            path: `/my-homes/${home.id}/preview`,
            query: {
              checkIn: checkIn?.id,
            },
          }"
          :disabled="!checkIsValid"
        >
          Preview
        </v-btn>
      </div>
    </v-footer>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/services/api-service';
import EmptyData from '@/components/global/EmptyData.vue';
import { compressAccurately, filetoDataURL } from 'image-conversion';
import { set } from 'vue';
import ConfirmationDialog from '@/components/global/ConfirmationDialog.vue';
import ImageViewerDialog from '@//components/global/ImageViewerDialog.vue';
import { PropertyCheckInSteps } from '@/data/constants';
import { extractExif } from '@/services/app.service';

export default {
  name: 'HomeCheckIn',
  components: {
    EmptyData,
    ConfirmationDialog,
    ImageViewerDialog,
  },
  computed: {
    ...mapGetters(['customer', 'properties', 'pendingHomeCheckIns']),
    checkIsValid() {
      return this.steps
        .filter((e) => this.defaultSteps.includes(e.key))
        .every((e) =>
          !e.gate || e?.gate?.value
            ? this.propertyImages[e?.key]?.filter((e) => e.photoUrl)?.length >=
              e?.minPhotos
            : true
        );
    },
    getFormattedCheckInStatus() {
      if (this.checkInStatus === 'pending') {
        return 'In Review';
      } else if (this.checkInStatus === 'onhold') {
        return 'On Hold';
      }
      return this.checkInStatus;
    },
    checkInStatus() {
      return this.checkIn?.status?.toLowerCase();
    },
  },
  data() {
    return {
      loading: true,
      home: null,
      checkIn: null,
      currentStep: 1,
      dialog: false,
      showDeleteDialog: false,
      showSyncErrorDialog: false,
      confirmImgDialog: {
        show: false,
        description: '',
        imgPath: '',
        fileName: '',
        exif: null,
      },
      isSyncingPhoto: false,
      modalTitle: '',
      previewImg: '',
      propertyImages: {},
      activeEditImgIndex: 0,
      activeEditImgKey: '',
      defaultSteps: [],
      loadingImages: [],
      steps: PropertyCheckInSteps,
    };
  },
  watch: {
    currentStep(_newValue, prevValue) {
      const isValid =
        !this.steps[prevValue - 1]?.gate ||
        this.steps[prevValue - 1]?.gate?.value
          ? this.propertyImages[this.steps[prevValue - 1]?.key]?.filter(
              (e) => e.photoUrl
            )?.length >= this.steps[prevValue - 1]?.minPhotos
          : true;
      this.steps[prevValue - 1].isInvalid = !isValid;
      this.steps[prevValue - 1].isValid = isValid;
    },
  },
  beforeMount() {
    this.home = this.properties?.find(
      (e) => e?.id === this.$route.params['id']
    );
    this.checkIn = this.home?.checkins?.find(
      (e) => e.id === this.$route.query['checkIn']
    );
    this.defaultSteps = this.$route.meta['defaultSteps'];
    if (this.pendingHomeCheckIns?.[this.home?.id]) {
      this.$store.commit('DELETE_PENDING_HOME_CHECK_INS', { id: this.home.id });
    }
    this.steps.forEach((e) => {
      const imgArray = this.getPropertyImgArray(e?.key, e.maxPhotos);
      if (imgArray.length > 0 && e.gate) {
        e.gate.value = true;
      }
      if (imgArray.length > 0) {
        if (!e.gate || e?.gate?.value) {
          const isValid =
            this.propertyImages[e.key]?.filter((e) => e.photoUrl)?.length >=
            e?.minPhotos;
          e.isInvalid = !isValid;
          e.isValid = isValid;
        } else {
          e.isInvalid = false;
          e.isValid = true;
        }
      }
    });
  },
  methods: {
    getPropertyImgArray(key, length) {
      if (this.checkIn) {
        const uploadedImages =
          this.checkIn.steps.find((e) => e.type === key)?.photos ?? [];
        set(
          this.propertyImages,
          key,
          uploadedImages?.map((e) => ({
            id: e.id,
            photoUrl: e.photoUrl,
            synced: true,
            name: e.fileName,
          }))
        );
        return uploadedImages;
      } else {
        const hasPendingImages =
          this.pendingHomeCheckIns?.[this.home?.id]?.[key] || [];
        set(this.propertyImages, key, [
          ...hasPendingImages,
          ...Array(length - hasPendingImages?.length)
            .keys()
            .map(() => ({
              id: crypto.randomUUID(),
              photoUrl: '',
              synced: false,
              name: '',
              longitude: 0,
              latitude: 0,
              timestamp: '',
              deviceMake: '',
              deviceModel: '',
            })),
        ]);
        return hasPendingImages;
      }
    },
    openPreviewDialog(imagePath, title) {
      this.previewImg = imagePath;
      this.modalTitle = title;
      this.dialog = true;
    },
    openDeleteDialog(key, index) {
      this.activeEditImgIndex = index;
      this.activeEditImgKey = key;
      this.showDeleteDialog = true;
    },
    onChangeImg(key, index) {
      this.activeEditImgKey = key;
      this.activeEditImgIndex = index;
      this.$refs?.[`fileRef${key}`]?.[0]?.click();
    },
    async onSyncImg(key, image, index) {
      try {
        this.loadingImages.push(image?.id);
        this.activeEditImgKey = key;
        this.activeEditImgIndex = index;
        await this.uploadPhoto(image.name, image.photoUrl);
      } finally {
        this.loadingImages.shift();
      }
    },
    async onImageChange(event, instruction) {
      const files = event.target.files;
      if (!files || files.length == 0) {
        return;
      }
      const file = files.length > 0 && files[0];
      if (!file || file.length == 0) {
        return;
      }
      const blob = new Blob([file], {
        type: file.type,
      });

      const shouldCompress = blob.size / 1024 > this.maxSize;
      const compressed = shouldCompress
        ? await compressAccurately(blob, {
            size: this.maxSize,
            accuracy: 0.85,
            height: 1080,
          })
        : blob;
      const dataUri = await filetoDataURL(compressed);
      const exif = await extractExif(file);
      this.confirmImgDialog = {
        show: true,
        description: instruction,
        imgPath: dataUri,
        fileName: file?.name,
        exif,
      };
    },
    onDeleteImage() {
      const prevImages = JSON.parse(
        JSON.stringify(this.propertyImages[this.activeEditImgKey])
      );
      prevImages[this.activeEditImgIndex] = {
        ...prevImages[this.activeEditImgIndex],
        synced: false,
        photoUrl: '',
        name: '',
      };
      set(this.propertyImages, this.activeEditImgKey, prevImages);
      this.showDeleteDialog = false;
      this.$store.commit('DELETE_PENDING_HOME_CHECK_INS_IMG', {
        id: this.home.id,
        key: this.activeEditImgKey,
        imgId: prevImages[this.activeEditImgIndex].id,
      });
    },
    async uploadPhoto(fileName, base64, exif) {
      try {
        this.isSyncingPhoto = true;
        const uploadPacket = {
          fileUploadType: this.activeEditImgKey,
          fileName: fileName,
          base64: base64?.split(',')[1],
        };
        const url = `customers/${this.customer.id}/upload-file`;
        const response = await api.post(url, uploadPacket);
        if (response.data.storageUri) {
          this.updateLocalPhoto(response.data.storageUri, fileName, exif);
        }
      } catch (error) {
        this.updateLocalPhoto(base64, fileName, exif, false);
        this.showSyncErrorDialog = true;
      } finally {
        this.confirmImgDialog.show = false;
        this.isSyncingPhoto = false;
      }
    },
    updateLocalPhoto(imgPath, name, exif, synced = true) {
      const prevImages = JSON.parse(
        JSON.stringify(this.propertyImages[this.activeEditImgKey])
      );
      prevImages[this.activeEditImgIndex] = {
        ...prevImages[this.activeEditImgIndex],
        synced: synced,
        photoUrl: imgPath,
        name,
        ...(exif && {
          longitude: exif.longitude,
          latitude: exif.latitude,
          timestamp: exif.dateTimeOriginal,
          deviceMake: exif.deviceMake,
          deviceModel: exif.deviceMake,
        }),
      };
      set(this.propertyImages, this.activeEditImgKey, prevImages);
      this.$store.commit('SET_PENDING_HOME_CHECK_INS', {
        id: this.home.id,
        key: this.activeEditImgKey,
        data: prevImages,
      });
    },
    onCheckBoxChange(checked, key) {
      if (!checked) {
        let prevImages = JSON.parse(JSON.stringify(this.propertyImages[key]));
        prevImages = prevImages.map((e) => ({
          ...e,
          photoUrl: '',
          synced: false,
        }));
        set(this.propertyImages, key, prevImages);
        this.$store.commit('SET_PENDING_HOME_CHECK_INS', {
          id: this.home.id,
          key: key,
          data: [],
        });
      }
    },
  },
};
</script>

<style scoped>
.property-img-button {
  position: relative;
  width: 160px;
  min-width: 160px;
  height: 160px;
  background: rgba(123, 97, 255, 0.267);
  border-radius: 2px;
}
.img-overlay,
.image-action-icon,
.image-count {
  position: absolute;
}
.image-action-icon {
  bottom: 8px;
}
.image-count {
  top: -6px;
  right: -12px;
}
.retake-icon {
  right: 8px;
}
.delete-icon {
  right: 38px;
}
.cloud-icon {
  top: 4px;
  right: 4px;
}
.cloud-check {
  height: fit-content;
  top: 8px;
  right: 8px;
}
.images-wrapper {
  display: flex;
  gap: 16px;
  overflow: auto;
  padding: 16px 0;
}
.images-wrapper::-webkit-scrollbar {
  height: 5px;
}
.images-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.images-wrapper::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
.img-confirm-title {
  position: relative;
}
.img-confirm-retake {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.cursor-auto {
  cursor: auto;
}
.status-badge {
  height: 26px;
}
.check-in-footer {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}
</style>

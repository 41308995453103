<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" xl="6">
        <RewardsSummary :customer="customer" />
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
      <v-col cols="12" md="10" xl="6">
        <Discounts :customer="customer" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="10" xl="6" class="timeline-container">
        <Payouts :customer="customer" />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'

  import RewardsSummary from "@/components/customer/RewardsSummary"
  import Discounts from "@/components/customer/Discounts"
  import Payouts from "@/components/customer/Payouts"

  export default {
    name: 'Rewards',

    components: {
      RewardsSummary,
      Discounts,
      Payouts
    },

    computed: {
      ...mapGetters(["customer"]),
    },

  }
</script>

<style scoped>
  .timeline-container {
    padding-bottom: 0;
    padding-top: 0;
  }

  .timeline-container>>>.v-timeline-item__dot--small {
    height: 8px;
    left: calc(50% - 4px);
    width: 8px;
  }

  .timeline-container>>>.v-timeline--reverse.v-timeline--dense:before {
    right: 4px;
    left: initial;
  }

  .timeline-container>>>.v-timeline-item__divider {
    min-width: 32px;
    justify-content: flex-end;
  }

  .timeline-container>>>.v-timeline-item__body {
    max-width: calc(100% - 32px);
    border: solid 2px var(--v-primary-base);
    border-radius: 8px;
  }

  .theme--light.v-timeline::before {
    background: #eee;
  }
</style>
<template>
  <v-container class="bg-white px-0 py-0">
    <!-- <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row> -->

    <v-stepper class="custom-stepper" v-model="currentStep" vertical>
      <template v-for="(step, index) in steps">
        <v-stepper-step
          class="custom-step-header"
          :key="step.key"
          :step="index + 1"
          editable
        >
          <div class="custon-avatar">
            <v-img width="40" :src="step?.placeholderIcon" contain></v-img>

            <v-icon
              v-if="currentStep === index + 1"
              class="edit-icon"
              color="primary"
              >$vuetify.icons.pencil_circle</v-icon
            >
            <v-icon v-else-if="step.isInvalid" class="edit-icon" color="danger"
              >$vuetify.icons.pages_about</v-icon
            >
            <v-icon v-else-if="step.isValid" class="edit-icon" color="success"
              >$vuetify.icons.check_circle</v-icon
            >
          </div>
          {{ step.title }}
        </v-stepper-step>

        <v-stepper-content :key="'content' + step.key" :step="index + 1">
          <v-form v-model="valid" ref="form">
            <v-form
              v-model="policyHolderForm"
              ref="policyHolderForm"
              v-if="step.key === 'PolicyHolder'"
            >
              {{ step.categoryInstructions }} {{ policyHolderForm }}
              <v-row class="mt-4" dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="firstname"
                    :rules="nameRules"
                    required
                    label="First name"
                    disabled
                    append-icon="$vuetify.icons.global_required"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="lastname"
                    :rules="nameRules"
                    required
                    label="Last name"
                    disabled
                    append-icon="$vuetify.icons.global_required"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="occupation"
                    :rules="occupationRules"
                    required
                    label="Occupation"
                    disabled
                    append-icon="$vuetify.icons.global_required"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <MobilePhoneInput
                    outlined
                    v-model="phone"
                    :default-value="phone"
                    return-international
                    :country-code="countryCode"
                    :allow-country-change="false"
                    :hint="`Number will be saved as ${phone || ' - '}`"
                    label="Mobile Number"
                    disabled
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    v-model="email"
                    :rules="emailRules"
                    required
                    label="Primary e-mail used for notifications"
                    type="email"
                    append-icon="$vuetify.icons.global_required"
                    disabled
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog
                    ref="dateModalRef"
                    v-model="dateModal"
                    :return-value.sync="birthDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="birthDate"
                        label="Date of Birth"
                        append-icon="mdi-calendar"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="birthDateRules"
                        disabled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="birthDate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dateModalRef[0].save(birthDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-form>
            <template v-if="step.key === 'CoApplicant'">
              <v-row class="mt-4" dense>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="isEnableCoApplication"
                    :label="step.gate.question"
                    disabled
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-form
                ref="coApplicantForm"
                v-model="coApplicantForm"
                v-if="isEnableCoApplication"
              >
                {{ step.categoryInstructions }}
                <v-row class="mt-4" dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="coFirstname"
                      :rules="nameRules"
                      required
                      label="First name"
                      append-icon="$vuetify.icons.global_required"
                      disabled
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="coLastname"
                      :rules="nameRules"
                      required
                      label="Last name"
                      append-icon="$vuetify.icons.global_required"
                      disabled
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="coOccupation"
                      :rules="occupationRules"
                      required
                      label="Occupation"
                      append-icon="$vuetify.icons.global_required"
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-dialog
                      ref="coDateModalRef"
                      v-model="coDateModal"
                      :return-value.sync="coBirthDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="coBirthDate"
                          label="Date of Birth"
                          append-icon="mdi-calendar"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                          :rules="birthDateRules"
                          disabled
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="primary"
                        v-model="coBirthDate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="coDateModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.coDateModalRef[0].save(coBirthDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-form>
            </template>
            <template v-if="step.key === 'DeclarationForm'">
              {{ step.categoryInstructions }}
              <a @click="openPreviewDialog(step.sampleImage)"
                >Click here to view a sample.
              </a>
              <!-- <DevicePhotoFile
                  block
                  v-model="declarationFormPhotos"
                  file-type="declarationFormPhoto"
                  ref="declarationform_file_picker"
                >
                  <v-btn class="mx-2 my-2" fab dark small color="primary">
                    <v-icon dark> $vuetify.icons.actions_add </v-icon>
                  </v-btn>
                </DevicePhotoFile> -->
              <div class="image-wrapper">
                <div
                  class="image-container d-flex align-center"
                  v-if="declarationFormPhotosPreview.length > 0"
                >
                  <div
                    class="img-preview d-flex align-center justify-center"
                    :class="{
                      'pdf-preview': img?.fileMimeType === 'application/pdf',
                    }"
                    v-for="(img, imgIndex) in declarationFormPhotosPreview"
                    :key="imgIndex"
                    @click="openPreviewDialog(img.photoUrl, img.fileMimeType)"
                  >
                    <v-img
                      v-if="img?.fileMimeType !== 'application/pdf'"
                      width="200px"
                      height="200px"
                      max-height="200"
                      cover
                      :src="img.photoUrl"
                    ></v-img>
                    <template v-else>
                      <v-img
                        width="100"
                        max-width="100"
                        src="/assets/policy/generic_document.svg"
                      ></v-img>

                      <div
                        class="img-name text-body-2 text-truncate text-center"
                      >
                        {{ step.key }}
                      </div>
                    </template>
                    <!-- <v-btn
                      class="image-action-icon delete-icon"
                      icon
                      rounded
                      @click.stop="openDeleteDialog(step.key, imgIndex)"
                    >
                      <v-icon color="accent"
                        >$vuetify.icons.actions_delete</v-icon
                      >
                    </v-btn> -->
                  </div>
                </div>
              </div>
            </template>
            <template v-if="step.key === 'InsuranceCard'">
              <v-row class="mt-4" dense>
                <v-col cols="12" md="6">
                  <v-checkbox
                    :input-value="insuranceCardPhotosPreview?.length > 0"
                    :label="step.gate.question"
                    disabled
                  ></v-checkbox>
                </v-col>
              </v-row>
              <template v-if="insuranceCardPhotosPreview?.length > 0">
                {{ step.categoryInstructions }}
                <a @click="openPreviewDialog(step.sampleImage)"
                  >Click here to view a sample.
                </a>
                <!-- <DevicePhotoFile
                    block
                    v-model="insuranceCardPhotos"
                    file-type="insuranceCardPhotos"
                    ref="insuranceCard_file_picker"
                  >
                    <v-btn class="mx-2 my-2" fab dark small color="primary">
                      <v-icon dark> $vuetify.icons.actions_add </v-icon>
                    </v-btn>
                  </DevicePhotoFile> -->
                <div class="image-wrapper">
                  <div class="image-container d-flex align-center">
                    <div
                      class="img-preview d-flex align-center justify-center"
                      :class="{
                        'pdf-preview': img?.fileMimeType === 'application/pdf',
                      }"
                      v-for="(img, imgIndex) in insuranceCardPhotosPreview"
                      :key="imgIndex"
                      @click="openPreviewDialog(img.photoUrl, img.fileMimeType)"
                    >
                      <v-img
                        v-if="img?.fileMimeType !== 'application/pdf'"
                        width="200px"
                        height="200px"
                        max-height="200"
                        cover
                        :src="img.photoUrl"
                      ></v-img>
                      <template v-else>
                        <v-img
                          width="100"
                          max-width="100"
                          src="/assets/policy/generic_document.svg"
                        ></v-img>

                        <div
                          class="img-name text-body-2 text-truncate text-center"
                        >
                          {{ step.key }}
                        </div>
                      </template>
                      <!-- <v-btn
                        class="image-action-icon delete-icon"
                        icon
                        rounded
                        @click="openDeletDialog(step.key, imgIndex)"
                      >
                        <v-icon color="accent"
                          >$vuetify.icons.actions_delete</v-icon
                        >
                      </v-btn> -->
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="step.key === 'DriversLicense'">
              {{ step.categoryInstructions }}
              <a @click="openPreviewDialog(step.sampleImage)"
                >Click here to view a sample.
              </a>
              <!-- <DevicePhotoFile
                  block
                  v-model="drivingLicensePhotos"
                  file-type="drivingLicensePhoto"
                  ref="drivingLicense_file_picker"
                  allowFileType="both"
                >
                  <v-btn class="mx-2 my-2" fab dark small color="primary">
                    <v-icon dark> $vuetify.icons.actions_add </v-icon>
                  </v-btn>
                </DevicePhotoFile> -->
              <div class="image-wrapper">
                <div class="image-container d-flex align-center">
                  <div
                    class="img-preview d-flex align-center justify-center"
                    :class="{
                      'pdf-preview': img?.fileMimeType === 'application/pdf',
                    }"
                    v-for="(img, imgIndex) in drivingLicensePhotosPreview"
                    :key="imgIndex"
                    @click="openPreviewDialog(img.photoUrl, img.fileMimeType)"
                  >
                    <v-img
                      v-if="img?.fileMimeType !== 'application/pdf'"
                      width="200px"
                      height="200px"
                      max-height="200"
                      cover
                      :src="img.photoUrl"
                    ></v-img>
                    <template v-else>
                      <v-img
                        width="100"
                        max-width="100"
                        src="/assets/policy/generic_document.svg"
                      ></v-img>

                      <div
                        class="img-name text-body-2 text-truncate text-center"
                      >
                        {{ step.key }}
                      </div>
                    </template>
                    <!-- <v-btn
                      class="image-action-icon delete-icon"
                      icon
                      rounded
                      @click.prevent="openDeletDialog(step.key, imgIndex)"
                    >
                      <v-icon color="accent"
                        >$vuetify.icons.actions_delete</v-icon
                      >
                    </v-btn> -->
                  </div>
                </div>
              </div>
            </template>
          </v-form>
        </v-stepper-content>
      </template>
    </v-stepper>
    <ImageViewerDialog
      :isOpen="imgDialog"
      :doc-type="previewImg.type"
      :imgUrl="previewImg?.filePath"
      @onClose="imgDialog = false"
    />
    <!-- <v-dialog max-width="500" v-model="imgDialog">
        <template>
          <v-card rounded="lg">
            <v-card-title
              class="preview-modal-title d-flex justify-space-between align-center"
            >
              <v-btn icon rounded @click="imgDialog = false">
                <v-icon>$vuetify.icons.global_close</v-icon>
              </v-btn>
            </v-card-title>
            <v-img v-if="previewImg?.type !== 'pdf'" :src="previewImg"></v-img>
            <pdf-preview v-else :base64Pdf="previewImg.src"></pdf-preview>
          </v-card>
        </template>
      </v-dialog> -->
    <v-dialog v-model="showDeleteDialog" max-width="345">
      <v-card>
        <v-card-title>
          Are you sure you want to delete this photo?
        </v-card-title>
        <v-card-text>
          This is a permanent action and you will not be able to retrieve your
          photo.
        </v-card-text>
        <v-card-actions class="shrink">
          <v-spacer />
          <v-btn text color="primary" @click="showDeleteDialog = false">
            Cancel
          </v-btn>
          <v-btn text color="grey darken-2" @click="deleteImage()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import EmptyData from '@/components/global/EmptyData.vue';
import MobilePhoneInput from '@/components/global/MobilePhoneInput';
import DevicePhotoFile from '@/components/global/DevicePhotoFile';
import PdfPreview from '@/components/global/PdfPreview.vue';
import ImageViewerDialog from '@//components/global/ImageViewerDialog.vue';
import { PolicyCheckInSteps } from '../data/constants';

export default {
  name: 'PolicyCheckIn',
  components: {
    EmptyData,
    MobilePhoneInput,
    DevicePhotoFile,
    PdfPreview,
    ImageViewerDialog,
  },
  computed: {
    ...mapGetters(['user', 'customer', 'policies']),
    nameFirst() {
      if (!this.user || !this.user.name) {
        return null;
      }
      let split = this.user.name.split(' ');
      return split[0];
    },
    nameLast() {
      if (!this.user || !this.user.name) {
        return null;
      }
      let split = this.user.name.split(' ');
      return split[1];
    },
    countryFlag() {
      const code = this.countryCode && this.countryCode.toLowerCase();
      return `https://flagcdn.com/${code}.svg`;
    },
    authProvider() {
      return this.user && this.user.provider;
    },
    isPhoneProvider() {
      return this.authProvider == 'phone';
    },
    customerInfo() {
      return this.policyData?.customerInfo;
    },
    goApplicantInfo() {
      return this.policyData?.coApplicantInfo;
    },
    isEnableCoApplication() {
      return (
        this.goApplicantInfo?.nameLast ||
        this.goApplicantInfo?.nameFirst ||
        this.goApplicantInfo?.occupation ||
        this.goApplicantInfo?.dateOfBirth
      );
    },
  },
  watch: {
    declarationFormPhotos(v) {
      if (v?.thumbnail) {
        this.declarationFormPhotosPreview.push(v);
        this.declarationFormPhotos = null;
      }
    },
    insuranceCardPhotos(v) {
      if (v?.thumbnail) {
        this.insuranceCardPhotosPreview.push(v);
        this.insuranceCardPhotos = null;
      }
    },
    drivingLicensePhotos(v) {
      if (v?.thumbnail) {
        this.drivingLicensePhotosPreview.push(v);
        this.drivingLicensePhotos = null;
      }
    },
  },
  data() {
    return {
      loading: true,
      dateModal: false,
      coDateModal: false,
      currentStep: 1,
      valid: false,
      policyHolderForm: false,
      coApplicantForm: false,
      firstname: null,
      lastname: null,
      occupation: null,
      coFirstname: null,
      coLastname: null,
      coOccupation: null,
      birthDate: null,
      coBirthDate: null,
      imgDialog: false,
      previewImg: {
        type: 'image',
        filePath: '',
      },
      showDeleteDialog: false,
      declarationFormPhotos: null,
      insuranceCardPhotos: null,
      drivingLicensePhotos: null,
      activeEditImgIndex: null,
      activeCategory: null,
      insuranceCardPhotosPreview: [],
      declarationFormPhotosPreview: [],
      drivingLicensePhotosPreview: [],
      birthDateRules: [(v) => !!v || 'Birthdate is required'],
      occupationRules: [(v) => !!v || 'Occupation is required'],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
      ],

      email: null,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => (v && /.+@.+/.test(v)) || 'Please enter a valid E-mail',
      ],
      country: 'United States',
      countryCode: 'US',
      dialCode: '1',
      phone: null,
      steps: PolicyCheckInSteps,
      validFormsKeys: [],
      policyData: null,
    };
  },
  mounted() {
    this.policyData = this.policies?.find(
      (e) => e.id === this.$route.params['id']
    );
    if (this.policyData?.type?.toLowerCase() === 'home') {
      this.steps = PolicyCheckInSteps.filter((e) => e.key !== 'InsuranceCard');
    }
    this.firstname = this.policyData?.customerInfo?.nameFirst;
    this.lastname = this.policyData?.customerInfo?.nameLast;
    this.occupation = this.policyData?.customerInfo?.occupation;
    this.phone = this.policyData?.customerInfo?.contactPhone.slice(2);
    this.email = this.policyData?.customerInfo?.contactEmail;
    this.birthDate = this.policyData?.customerInfo?.dateOfBirth;
    this.declarationFormPhotosPreview = this.policyData.files.filter(
      (e) => e.type === 'DEC_Home'
    );
    this.drivingLicensePhotosPreview = this.policyData.files.filter(
      (e) => e.type === 'DriverLicense_Front'
    );
    this.insuranceCardPhotosPreview = this.policyData.files.filter(
      (e) => e.type === 'InsuranceCard'
    );
    this.checkFormValidation();
  },
  methods: {
    checkFormValidation() {
      this.steps.forEach((e) => {
        const stepKey = e.key;
        const gate = e?.gate;
        let isValid = true;
        switch (stepKey) {
          case 'PolicyHolder':
            isValid = this.policyHolderForm;
            break;
          case 'CoApplicant':
            isValid = gate.value ? this.coApplicantForm : true;
            break;
          case 'DeclarationForm':
            isValid =
              !gate || gate?.value
                ? this.declarationFormPhotosPreview?.filter((e) => e.photoUrl)
                    ?.length >= e?.minPhotos
                : true;
            break;
          default:
            isValid =
              !gate || gate?.value
                ? this.drivingLicensePhotosPreview?.filter((e) => e.photoUrl)
                    ?.length >= e?.minPhotos
                : true;
            break;
        }
        e.isInvalid = !isValid;
        e.isValid = isValid;
      });
    },
    openPreviewDialog(photoUrl, fileType = 'image') {
      this.previewImg = {
        type: fileType,
        filePath: photoUrl,
      };
      this.imgDialog = true;
    },
    openDeleteDialog(key, index) {
      this.activeCategory = key;
      this.activeEditImgIndex = index;
      this.showDeleteDialog = true;
    },
    deleteImage() {
      this.showDeleteDialog = false;
      switch (this.activeCategory) {
        case 'DeclarationForm':
          this.declarationFormPhotosPreview.splice(this.activeEditImgIndex, 1);
          break;
        case 'InsuranceCard':
          this.insuranceCardPhotosPreview.splice(this.activeEditImgIndex, 1);
          break;
        case 'DriversLicense':
          this.drivingLicensePhotosPreview.splice(this.activeEditImgIndex, 1);
          break;
        default:
          break;
      }
      this.activeEditImgIndex = null;
      this.activeCategory = null;
    },
  },
};
</script>
<style lang="scss">
.custom-stepper {
  box-shadow: unset !important;
}
.custom-step-header {
  .v-stepper__label {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 20px;
    font-weight: bold;
    .custon-avatar {
      position: relative;
      .check-icon,
      .edit-icon {
        position: absolute;
        top: -4px;
        right: -4px;
        background: white;
        border-radius: 50%;
      }
      .check-icon {
        font-size: 18px;
      }
      .edit-icon {
        font-size: 22px;
      }
    }
  }
  .v-stepper__step__step {
    display: none;
  }
}
.bg-white {
  background: white;
}
.image-wrapper {
  .image-container {
    overflow: auto;
    gap: 20px;
    .img-preview {
      position: relative;
      width: 200px;
      height: 200px;
      .image-action-icon {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .img-name {
        position: absolute;
        bottom: 4px;
        width: 100%;
        padding: 0 12px;
      }
    }
    .pdf-preview {
      background: rgba(123, 97, 255, 0.267);
    }
  }
}
</style>

<template>
  <div>
    <vue-pdf-embed
      :source="base64Pdf"
      style="width: 100%; height: 600px"
    ></vue-pdf-embed>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "PdfPreview",
  components: {
    VuePdfEmbed,
  },
  props: {
    base64Pdf: {
      type: String,
      required: true,
    },
  },
};
</script>

import exifr from 'exifr';
import store from '@/store';
import api from '@/services/api-service';
import { KYNAT_REFERRAL_CODE } from '../data/constants';

export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      var options = {
        enableHighAccuracy: true,
        timeout: 4000,
        maximumAge: 0,
      };
      const onSuccess = (position) => resolve(position);
      const onError = (error) => reject(error);
      navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
    }
  });
};

export const extractExif = async (file) => {
  try {
    const device = store.getters.device;
    const global =
      (await exifr.parse(file, ['DateTimeOriginal', 'Make', 'Model'])) || {};
    const { coords } = await getCurrentPosition();
    let EXIF = {
      dateTimeOriginal:
        (global && global.DateTimeOriginal) ||
        file.lastModifiedDate.toISOString(),
      deviceMake: (global && global.Make) || device.manufacturer,
      deviceModel: (global && global.Model) || device.product,
      latitude: coords?.latitude,
      longitude: coords?.longitude,
    };
    return EXIF;
  } catch (error) {
    throw error;
  }
};

export const showCustomBuyerExperience = async () => {
  const customer = store.getters.customer;
  if (customer?.referalCode?.toUpperCase() == KYNAT_REFERRAL_CODE) {
    const checkinSummary = customer?.checkinSummary;
    const numProperties = checkinSummary?.numProperties;
    const numPropertyCheckins = checkinSummary?.numPropertyCheckins;
    if (numProperties === 0 || numPropertyCheckins === 0) {
      return {
        isReferral: true,
        navPath: numProperties === 0 ? '/kynat-properties-referral' : '/my-homes',
      };
    }
  } else {
    if (customer?.contributorId && customer?.showContributorOnboarding) {
      const response = await api.get(
        `customers/${customer?.id}/${customer?.contributorId}/answer-tos`
      );
      const answer = response.data.answer;
      if (!answer || (answer && answer == 'None')) {
        return {
          isReferral: true,
          navPath: '/data-contributor-1',
        };
      }
    }
  }
  return {
    isReferral: false,
    navPath: '',
  };
};

export const updateTOSAnswer = (customerId, dataBuyerId, answer) => {
  return api.post(`customers/${customerId}/answer-tos`, {
    contributorId: dataBuyerId,
    answer: answer,
  });
};

<template>
  <v-dialog v-model="isOpen" max-width="345" persistent>
    <v-card>
      <v-card-title>{{ title }} </v-card-title>
      <v-card-text>
        {{ bodyText }}
      </v-card-text>
      <v-card-actions class="shrink">
        <v-spacer />
        <v-btn
          v-if="cancelButton"
          text
          :color="cancelButtonColor"
          @click="$emit('onCancel')"
        >
          {{ cancelButton }}
        </v-btn>
        <v-btn text :color="confirmButtonColor" @click="$emit('onConfirm')">
          {{ confirmButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    bodyText: {
      type: String,
      required: true,
    },
    cancelButton: {
      type: String,
      required: false,
    },
    confirmButton: {
      type: String,
      required: false,
    },
    cancelButtonColor: {
      type: String,
      default: 'primary',
    },
    confirmButtonColor: {
      type: String,
      default: 'grey darken-2',
    },
  },
};
</script>

<template>
    <div class="device-photo-file">
        <input type="file" name="File" ref="fileinput" :accept="acceptedFileTypes" capture="environment"
            @change="onFilesChanged" />
        <v-btn @click="initiateFilePicker" text x-large v-bind="$attrs" :loading="rendering">
            <slot>pick a file</slot>
        </v-btn>
    </div>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import exifr from 'exifr'; //https://www.npmjs.com/package/exifr
    import {
        compressAccurately,
        filetoDataURL
    } from 'image-conversion'; //https://github.com/WangYuLue/image-conversion

    export default {
        name: "DevicePhotoFile",
        inheritAttrs: false,

        props: {
            maxSize: {
                type: Number,
                default: 200
            },
            fileType: {
                type: String,
                default: "GLOBAL"
            },
            allowFileType: {
                type: String,
                default: 'image'
            }
        },

        data() {
            return {
                rendering: false,
                geo: {},
                exif: null,
                snapDataUri: null,
            }
        },

        computed: {
            ...mapGetters(["device"]),
            width() {
                return window.innerWidth;
            },
            height() {
                return window.innerHeight;
            },
            acceptedFileTypes() {
            switch (this.fileType) {
                    case 'image':
                        return 'image/*';
                    case 'document':
                        return 'application/pdf';
                    case 'both':
                    default:
                        return 'image/*,application/pdf';
                }
            },
            base64Data() {
                if (!this.snapDataUri) {
                    return '';
                }
                return this.snapDataUri.replace(/^data:image\/[a-z]+;base64,/, "");
            },
            fileExtension() {
                if (!this.snapDataUri) {
                    return '';
                }
                let mimeType = this.snapDataUri.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                return mimeType.split("/")[1];
            }
        },

        watch: {
            async snapDataUri(v, ov) {
                this.$emit("input", {
                    exifMetaData: this.exif || {},
                    fileBase64Data: this.base64Data,
                    extension: this.fileExtension,
                    thumbnail: v,
                });
            },
        },

        methods: {
            async extractExifGeolocation(file) {
                try {
                    var geo = await exifr.gps(file);
                    return geo || {}
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "extractExifGeolocation"
                    }, error);
                    return {};
                }
            },

            _updateLocation(position) {
                const pos = position != null ? position.coords : {};
                this.geo.latitude = pos.latitude;
                this.geo.longitude = pos.longitude;
            },
            _errorHandler(err) {
                this.$Logger.error({
                    component: this.$options.name,
                    method: "extractDeviceGeolocation"
                }, err);
            },
            extractDeviceGeolocation() {
                try {
                    if (navigator.geolocation) {
                        //https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
                        var options = {
                            enableHighAccuracy: true,
                            timeout: 3000,
                            maximumAge: 0
                        };
                        navigator.geolocation.getCurrentPosition(this._updateLocation, this._errorHandler, options);
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "extractDeviceGeolocation"
                    }, error);
                }
            },

            async extractEXIF(file, modified) {
                try {
                    const global = await exifr.parse(file, ['DateTimeOriginal', 'Make', 'Model']) || {};

                    let EXIF = {
                        DateTimeOriginal: global && global.DateTimeOriginal || modified,
                        DeviceMake: global && global.Make || this.device.manufacturer,
                        DeviceModel: global && global.Model || this.device.product,
                        GPS_Latitude: this.geo && this.geo.latitude,
                        GPS_Longitude: this.geo && this.geo.longitude,
                    };

                    return EXIF;

                } catch (error) {

                    this.$Logger.error({
                        component: this.$options.name,
                        method: "extractEXIF"
                    }, error);

                    return null;
                }
            },

            async onFilesChanged(e) {
                try {
                    this.$Logger.track(`picture_upload_process`, {
                        component: this.$options.name,
                        type: this.fileType,
                    });


                    const files = e.target.files;
                    if (!files || files.length == 0) {
                        return;
                    }
                    const file = files.length > 0 && files[0];
                    if (!file || file.length == 0) {
                        return;
                    }
                    const blob = new Blob([file], {
                        type: file.type
                    });

                    this.rendering = true;

                    const shouldCompress = blob.size / 1024 > this.maxSize;
                    const compressed = shouldCompress ? await compressAccurately(blob, {
                        size: this.maxSize,
                        accuracy: 0.85,
                        height: 1080
                    }) : blob;

                    const modified = file.lastModifiedDate;
                    const exif = await this.extractEXIF(file, modified);
                    const dataUri = await filetoDataURL(compressed);

                    this.exif = exif;
                    this.snapDataUri = dataUri;
                    this.rendering = false;

                    this.$Logger.track(`picture_upload_completed`, {
                        component: this.$options.name,
                        exif: exif && JSON.stringify(exif),
                        type: this.fileType,
                    });
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "onFilesChanged"
                    }, error);
                }
            },

            initiateFilePicker() {
                this.$Logger.track(`picture_upload_init`, {
                    component: this.$options.name,
                    type: this.fileType,
                });
                this.$refs.fileinput.click();
            },
        },

        mounted() {
            // this.initiateFilePicker();
            this.extractDeviceGeolocation();
        }
    }
</script>

<style lang="css" scoped>
    .device-photo-file {
        position: relative;
        max-width: 100%;
    }

    .device-photo-file>input[type='file'] {
        visibility: hidden;
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
    }

    .device-photo-file .v-btn {
        height: auto;
        padding: 0;
        max-width: 100%;
        color: white;
        transition: filter 300ms ease-in;
    }

    .device-photo-file .v-btn.v-btn--disabled {
        filter: blur(1px) grayscale(0.7);
    }

    .device-photo-file>>>.v-btn__content {
        max-width: 100%;
    }
</style>
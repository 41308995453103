<template>
  <v-row>
    <v-col align="center">
      <h1 class="mt-4 text-h3 text-uppercase primary--text">{{ title1 }}</h1>
      <div class="success--text my-2">
        <v-icon color="success">$vuetify.icons.check_circle</v-icon>
        {{ successText }}
      </div>
      <v-img class="check-in-img" max-height="280" :src="imageUrl"></v-img>
      <h5 class="text-h5 text-uppercase mt-4">{{ title2 }}</h5>
      <p class="darken-2 mt-4 mx-2">
        {{ subTitle }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SetUpDone',
  props: {
    title1: {
      type: String,
      default: 'Yaaay!',
    },
    successText: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    title2: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.check-in-img {
  max-width: 150px;
}

.darken-2 {
  color: #616161;
}
</style>

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import Logger from "@/plugins/logger";
import Meta from "@/plugins/meta";
import '@/plugins/firebase'
import '@/registerServiceWorker'

Vue.config.productionTip = false;

import VuePageTransition from 'vue-page-transition'
import Vue2Filters from "vue2-filters";
import VueMoment from "vue-moment";

import BrandLogo from "@/components/appshell/BrandLogo";
import BrandLogoLoader from "@/components/appshell/BrandLogoLoader";
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VuePageTransition);
Vue.use(Logger);
Vue.use(Meta);
Vue.use(VueMoment);
Vue.use(Vue2Filters);
Vue.use(VueSocialSharing);


Vue.component("BrandLogo", BrandLogo);
Vue.component("BrandLogoLoader", BrandLogoLoader);

import {
  VueMaskDirective
} from 'v-mask'
Vue.directive('mask', VueMaskDirective);

new Vue({
  router,
  store,
  vuetify,

  created() {

    window.addEventListener("offline", () => {
      store.commit("SET_OFFLINE", true);
    });
    window.addEventListener("online", () => {
      store.commit("SET_OFFLINE", false);
    });


    if (navigator.connection) {
      navigator.connection.addEventListener('change', (e) => {
        // console.log("navigator.connection.change", e, navigator.connection);
        store.commit("SET_CONNECTION", navigator.connection.effectiveType);
      });
    }

  },

  render: h => h(App),
}).$mount('#app');
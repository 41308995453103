<template>
  <v-main>
    <navbar-top-with-back-btn
      class="top-header"
      :headerTitle="headerTitle"
    ></navbar-top-with-back-btn>
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-container class="px-6" v-if="survey">
      <v-list color="transparent">
        <h3 class="text-center primary--text">Sponsored By:</h3>
        <v-list-item class="px-0">
          <v-list-item-avatar size="50" class="rounded-0">
            <v-img :src="survey?.sponsor?.listLogo"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6">{{
              survey?.sponsor?.name
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-wrap text-body-1">
              {{ survey?.sponsor?.shortDescription }}
            </v-list-item-subtitle>
            <v-btn
              class="learn-more-btn pa-0 text-capitalize"
              text
              @click="getBuyerModalDiscount"
              :ripple="false"
              color="primary"
              >Learn More</v-btn
            >
          </v-list-item-content>
        </v-list-item>
        <p class="text-body-1 text-justify">{{ survey?.description }}</p>
        <v-card
          :key="item.id"
          v-for="item in survey?.questions"
          color="transparent"
          class="border-primary rounded-0 mb-3"
          outlined
        >
          <h3 class="py-2 px-3">
            {{ item.text }}
            <span v-if="item.isRequired" class="error--text">*</span>
          </h3>
          <v-container
            v-if="item.type === 'Select_Multi'"
            class="survey-checkbox-container"
          >
            <v-checkbox
              class="custom-checkbox"
              v-for="ans in item.answers"
              :key="ans.id"
              :label="ans.text"
              :value="ans.id"
              hide-details
              v-model="formData[item.id]"
              multiple
              :disabled="!!completedSurvey"
            ></v-checkbox>
          </v-container>
          <v-container
            class="survey-radio-container"
            v-if="item.type === 'Select_Single'"
          >
            <v-radio-group
              :disabled="!!completedSurvey"
              hide-details
              v-model="formData[item.id]"
            >
              <v-radio
                :key="ans.id"
                :value="ans.id"
                v-for="ans in item.answers"
                :label="ans.text"
              ></v-radio>
            </v-radio-group>
          </v-container>
          <v-container v-if="item.type === 'OpenText'">
            <v-text-field
              v-model="formData[item.id]"
              hide-details
              outlined
              :disabled="!!completedSurvey"
              class="rounded-0 mb-3"
            ></v-text-field>
          </v-container>
        </v-card>
      </v-list>
    </v-container>
    <v-footer v-if="!loading && !completedSurvey" app outlined>
      <v-row>
        <v-col align="center" class="mt-2 pb-0">
          <v-btn
            color="primary"
            :disabled="!allRequiredFieldsFilled"
            @click="submit"
            >Finish</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="mb-2">
          <a
            @click="openSponserCustomiseModal"
            class="text-decoration-underline text-uppercase text-body-2"
            >Choose which companies can buy your info</a
          >
        </v-col>
      </v-row>
    </v-footer>

    <!-- learn more modal -->
    <v-dialog v-model="learnMoreModal" fullscreen hide-overlay>
     <buyer-details @dismiss="learnMoreModal = false" :buyerData="buyerDiscount"></buyer-details>
    </v-dialog>

    <!-- customize choose sponser modal -->
    <v-dialog v-model="customizeSponserModal" fullscreen hide-overlay>
      <v-card>
        <v-toolbar elevation="0">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="primary" text @click="customizeSponserModal = false">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row v-if="modalLoading" justify="center" align="center">
          <v-col align="center" cols="12" sm="8" md="6">
            <v-progress-circular
              align="center"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <template v-else>
          <v-container class="sponser-modal-container">
            <span
              >You’re in control. Choose which companies can buy your
              info.</span
            >
            <v-switch
              class="mt-6 mb-8"
              hide-details
              label="Maxmimize my earnings."
              :ripple="false"
              @change="saveCustomerDataBuyerPreferencesAllEnabled"
              v-model="customerDataBuyerPreferences.enableAllDataBuyers"
            ></v-switch>
            <p class="fs-18 mb-4">
              Rewards are only given by your selected buyers.
            </p>
            <v-card
              v-for="(reward, index) in surveySubmitRewards"
              :key="'r' + index"
            >
              <v-list-item>
                <v-list-item-avatar size="50" class="rounded-0">
                  <v-img :src="reward?.buyer?.listLogo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">{{
                    reward?.buyer?.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap text-body-1">
                    {{ reward?.buyer?.shortDescription }}
                  </v-list-item-subtitle>
                  <h4 class="mt-1 success--text">
                    {{ getRewardAmount(reward) }}
                  </h4>
                </v-list-item-content>
                <v-list-item-action
                  v-if="!customerDataBuyerPreferences.enableAllDataBuyers"
                >
                  <v-switch
                    :input-value="getDataBuyerStatus(reward.buyer.id)"
                    @change="
                      saveCustomerDataBuyerPreferences($event, reward.buyer.id)
                    "
                    :ripple="false"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-container>
        </template>
      </v-card>
    </v-dialog>

    <!-- Survey done modal -->
    <v-dialog v-model="surveyDoneModal" fullscreen hide-overlay>
      <survey-done :surveySubmitRewards="surveySubmitRewards"></survey-done>
    </v-dialog>
  </v-main>
</template>
<script>
import RewardCard from "@/components/customer/RewardCard";
import BuyerDetails from "@/components/customer/BuyerDetails";
import { mapGetters } from "vuex";
import NavbarTopWithBackBtn from "@/components/appshell/NavbarTopWithBackBtn.vue";

import api from "@/services/api-service";
import SurveyDone from "./SurveyDone.vue";
export default {
  name: "SurveyDetails",
  components: {
    RewardCard,
    NavbarTopWithBackBtn,
    SurveyDone,
    BuyerDetails
  },
  computed: {
    ...mapGetters(["customer"]),
    headerTitle() {
      return this.survey?.title || "Loading Survey";
    },
    allRequiredFieldsFilled() {
      if (this.survey?.questions?.length) {
        return Object.values(this.survey.questions).every((question) => {
          if (
            typeof this.formData[question.id] === "object" &&
            !this.formData[question.id]?.length
          ) {
            return;
          }
          return !question.isRequired || !!this.formData[question.id];
        });
      }
    },
  },
  data() {
    return {
      loading: true,
      formData: {},
      completedSurvey: null,
      learnMoreModal: false,
      customizeSponserModal: false,
      modalLoading: true,
      currentTab: "details",
      surveyDoneModal: false,
      survey: null,
      buyerDiscount: null,
      surveySubmitRewards: [],
      customerDataBuyerPreferences: {
        enableAllDataBuyers: false,
      },
    };
  },
  props: ["id"],
  methods: {
    navigate(id) {
      console.log("ev", ev);
    },
    async getSurvey() {
      try {
        const url = `survey-templates/v1/${this.$route.params.id}`;
        const response = await api.get(url, {
          requestId: url,
        });
        this.survey = response.data;
        if (this.$route?.query?.isSurveyFilled) {
          const url = `surveys/v1/${this.$route?.query?.isSurveyFilled}`;
          const response = await api.get(url, {
            requestId: url,
          });
          this.completedSurvey = response.data;
          this.preFillFormData();
        }
      } catch (error) {
        console.error("Error fetching survey details:", error);
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      this.loading = true;
      const plainFormData = JSON.parse(JSON.stringify(this.formData));
      const payload = {
        customerId: this.customer.id,
        surveyTemplateId: this.survey.id,
        answers: [],
      };

      this.survey.questions.forEach((question) => {
        if (
          question.type === "Select_Single" ||
          question.type === "Select_Multi"
        ) {
          if (plainFormData[question.id]) {
            if (Array.isArray(plainFormData[question.id])) {
              plainFormData[question.id].forEach((answerId) => {
                payload.answers.push({
                  surveyTemplateQuestionId: question.id,
                  surveyTemplateAnswerId: answerId,
                });
              });
            } else {
              payload.answers.push({
                surveyTemplateQuestionId: question.id,
                surveyTemplateAnswerId: plainFormData[question.id],
              });
            }
          }
        } else if (question.type === "OpenText") {
          if (plainFormData[question.id]) {
            payload.answers.push({
              surveyTemplateQuestionId: question.id,
              answerText: plainFormData[question.id],
            });
          }
        }
      });
      const url = "/surveys/v1";
      const response = await api.post(url, payload);
      console.log("survey finish api response", response);
      this.loading = false;
      this.surveyDoneModal = true;
    },
    preFillFormData() {
      if (this.completedSurvey && this.completedSurvey.records) {
        this.completedSurvey.records.forEach((record) => {
          if (record.type === "Select_Single") {
            const selectedAnswer = record.answers.find(
              (answer) => answer.selected
            );
            if (selectedAnswer) {
              this.$set(this.formData, record.id, selectedAnswer.id);
            }
          } else if (record.type === "Select_Multi") {
            const selectedAnswers = record.answers.filter(
              (answer) => answer.selected
            );
            if (selectedAnswers.length > 0) {
              this.$set(
                this.formData,
                record.id,
                selectedAnswers.map((answer) => answer.id)
              );
            }
          } else if (record.type === "OpenText") {
            this.$set(this.formData, record.id, record.answerText);
          }
        });
      }
    },
    async getBuyerModalDiscount() {
      this.learnMoreModal = true;
      this.modalLoading = true;
      const url = `data-buyers/${this.survey.sponsor.id}/discounts?state=${this.customer.state}&customerId=${this.customer.id}`;
      const response = await api.get(url, {
        requestId: url,
      });
      if (response?.data?.length) {
        this.buyerDiscount = response.data[0];
      }
      this.modalLoading = false;
    },
    getRewardAmount(reward) {
      let amount = 0;
      if (reward?.discounts?.length) {
        reward.discounts.forEach((r) => {
          amount += r.amount;
        });
      }
      return "$" + amount;
    },
    async openSponserCustomiseModal() {
      this.modalLoading = true;
      this.customizeSponserModal = true;
      const url = `surveys/v1/${this.customer.id}/${this.survey.id}/discounts`;
      const response = await api.get(url, {
        requestId: url,
      });
      this.surveySubmitRewards = response?.data || [];
      console.log(
        "discount response",
        JSON.parse(JSON.stringify(response.data))
      );
      const pUrl = `customers/${this.customer.id}/databuyerpreferences`;
      const result = await api.get(pUrl, {
        requestId: pUrl,
      });
      this.customerDataBuyerPreferences = result.data;
      this.modalLoading = false;
    },
    async saveCustomerDataBuyerPreferencesAllEnabled(enabled) {
      console.log("change preferences", enabled);
      const url = "customerdatabuyerpreferences";
      const payload = {
        customerId: this.customer.id,
        enableAllDataBuyers: enabled,
        dataBuyers: {},
      };
      // const response = await api.post(url, payload);
      // console.log('prefasdfasdf change response',response);
    },
    async saveCustomerDataBuyerPreferences(enabled, dataBuyerId) {
      const url = "customerdatabuyerpreferences";
      const payload = {
        customerId: this.customer.id,
        enableAllDataBuyers: false,
        dataBuyers: { [dataBuyerId]: enabled },
      };
      console.log("payload", payload);
      // const response = await api.post(url, payload);
      // console.log('prefasdfasdf change response',response);
    },
    getDataBuyerStatus(id) {
      if (this.customerDataBuyerPreferences.dataBuyers.length) {
        const find = this.customerDataBuyerPreferences.dataBuyers.find(
          (e) => e.dataBuyerId == id
        );
        if (find) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.getSurvey();
  },
};
</script>
<style scoped>
.top-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
.learn-more-btn {
  max-width: fit-content;
}
.border-primary {
  border-color: rgba(123, 97, 255, 0.267) !important;
}
.video-wrapper {
  width: 100%;
  max-height: 250px;
}
.borderless-table th,
.borderless-table td,
.borderless-table tr {
  border-bottom: none !important;
}
.borderless-table tbody th,
.borderless-table tbody td,
.borderless-table tbody tr {
  height: auto !important;
}
.fs-18 {
  font-size: 18px;
}
</style>
<template>
  <v-container>
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else-if="homes?.length">
      <template v-for="home in homes">
        <template v-if="home?.checkins?.length > 0">
          <v-col
            v-for="checkIn in home.checkins"
            cols="12"
            sm="6"
            md="3"
            v-bind:key="home?.id"
          >
            <DisplayCard :home="home" :checkIn="checkIn" />
          </v-col>
        </template>
        <v-col v-else cols="12" sm="6" md="3" :key="home?.id">
          <DisplayCard :home="home" />
        </v-col>
      </template>
    </v-row>
    <EmptyData
      v-else
      :time="5"
      header1="Invest in your home"
      header2="With this data, businesses (insurers, landscapers, roofers, etc.) can personalize offers for your home!"
      imgPath="assets/ownli/home_empty_state.png"
      :benefits="[
        'Home Check-In Reward.',
        'Have certified photos of your home condition in case of damages in the future.',
      ]"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/services/api-service';
import EmptyData from '@/components/global/EmptyData.vue';
import DisplayCard from '@/components/home/DisplayCard.vue';

export default {
  name: 'MyHome',
  components: {
    EmptyData,
    DisplayCard,
  },
  computed: {
    ...mapGetters(['customer']),
  },
  data() {
    return {
      loading: true,
      homes: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const url = `customers/${this.customer.id}/properties`;
        const response = await api.get(url, {
          requestId: url,
        });
        this.homes = response.data;
        this.$store.commit('SET_PROPERTIES', response.data);
      } catch (error) {
        console.error('Error fetching vehicles templates:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

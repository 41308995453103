export const PropertyCheckInSteps = [
  {
    key: 'ProofOfAddress',
    title: 'Proof of Address',
    sampleImage: '/assets/home/home_address_sample.jpg',
    placeholderIcon: '/assets/home/svg/home_address_icon.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_address.svg',
    categoryInstructions:
      'Take a photo clearly showing the address of your home. (E.g. The house number on your front door, garage or mail box.)',
    photoPromptInstructions:
      'Can you clearly see your home address in this photo? You’ll have the chance to take up to 2 photos.',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 2,
    minPhotos: 1,
  },
  {
    key: 'Front',
    sampleImage: '/assets/home/home_front_sample.jpg',
    placeholderIcon: '/assets/buyers/svg/datapoint_home.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_front.svg',
    categoryInstructions:
      'Take a photo clearly showing the front of your home.',
    photoPromptInstructions:
      'Can you clearly see the entire front of your house in this photo? This would include some surrounding area, such as the sky above the roof, the ground below the house and the complete width of the house. You’ll have the chance to take up to 4 photos.',
    title: 'Front of house',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 1,
  },
  {
    key: 'Back',
    sampleImage: '/assets/home/home_back_sample.jpg',
    placeholderIcon: '/assets/home/svg/home_back_icon.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_back.svg',
    categoryInstructions: 'Take a photo clearly showing the back of your home.',
    photoPromptInstructions:
      'Can you clearly see the entire back of your house in this photo? This would include some surrounding area, such as the sky above the roof, the ground below the house and the complete width of the house. You’ll have the chance to take up to 4 photos.',
    title: 'Back of house',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 1,
  },
  {
    key: 'Right',
    sampleImage: '/assets/home/home_right_sample.jpg',
    placeholderIcon: '/assets/home/svg/home_right_icon.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_right.svg',
    categoryInstructions:
      'Take a photo clearly showing the right side of your home. We know this can be difficult if you don’t have enough room on the sides of your house. You can take this photo from an angle if that helps or capture the side in a few photos.',
    photoPromptInstructions:
      'Can you clearly see the entire right side of your house in this photo? You’ll have the chance to take up to 4 photos.',
    title: 'Right side of house',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 1,
  },
  {
    key: 'Left',
    sampleImage: '/assets/home/home_left_sample.jpg',
    placeholderIcon: '/assets/home/svg/home_left_icon.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_left.svg',
    categoryInstructions:
      'Take a photo clearly showing the left side of your home. We know this can be difficult if you don’t have enough room on the sides of your house. You can take this photo from an angle if that helps or capture the side in a few photos.',
    photoPromptInstructions:
      'Can you clearly see the entire left side of your house in this photo? You’ll have the chance to take up to 4 photos. ',
    title: 'Left side of house',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 1,
  },
  {
    key: 'OutdoorsShed',
    sampleImage: '/assets/home/home_shed.jpg',
    placeholderIcon: '/assets/home/svg/home_shed_icon.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_shed.svg',
    categoryInstructions:
      'Take a photo clearly showing the outdoor structure(s).',
    photoPromptInstructions:
      'Can you clearly see all the outdoor structures in this photo? You’ll have the chance to take up to 4 photos.',
    title: 'Outdoor Structures',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    gate: {
      question:
        'Does this house have one or more outdoor structures like a shed or a carport?',
      value: false,
    },
    maxPhotos: 4,
    minPhotos: 1,
  },
  {
    key: 'Pool',
    sampleImage: '/assets/home/home_pool_sample.jpg',
    placeholderIcon: '/assets/home/svg/home_pool_icon.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_pool.svg',
    categoryInstructions:
      'Take a photo clearly showing the entire pool area and the gate.',
    photoPromptInstructions:
      'Can you clearly see the entire pool in this photo? You’ll have the chance to take up to 2 photos.',
    title: 'Pool',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    gate: {
      question: 'Does this house have a pool?',
      value: false,
    },
    maxPhotos: 2,
    minPhotos: 1,
  },
  {
    key: 'Fireplace',
    sampleImage: '/assets/home/home_fireplace_sample.jpg',
    placeholderIcon: '/assets/home/svg/home_fireplace.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_fireplace.svg',
    categoryInstructions:
      'Take at least 2 photos clearly showing the Fireplace in your house. Please take the photos from a slightly different angle to ensure we capture the whole Fireplace.',
    photoPromptInstructions:
      'Can you clearly see the entire Fireplace of your house in this photo? You’ll have the chance to take up to 4 photos. ',
    title: 'Fireplace',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 2,
  },
  {
    key: 'Kitchen',
    sampleImage: '/assets/home/home_kitchen_sample.jpg',
    placeholderIcon: '/assets/home/svg/home_kitchen.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_kitchen.svg',
    categoryInstructions:
      'Take at least 2 photos clearly showing the Kitchen in your house. Please take the photos from a slightly different angle to ensure we capture the whole Kitchen.',
    photoPromptInstructions:
      'Can you clearly see the entire Kitchen of your house in this photo? You’ll have the chance to take up to 4 photos. ',
    title: 'Kitchen',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 2,
  },
  {
    key: 'LivingRoom',
    sampleImage: '/assets/home/home_livingarea_sample.jpg',
    placeholderIcon: '/assets/home/svg/home_livingarea.svg',
    cameraIcon: '/assets/home/svg/camera_frame_home_livingarea.svg',
    categoryInstructions:
      'Take at least 2 photos clearly showing the Living Room in your house. Please take the photos from a slightly different angle to ensure we capture the whole Living Room.',
    photoPromptInstructions:
      'Can you clearly see the entire Living Room of your house in this photo? You’ll have the chance to take up to 4 photos. ',
    title: 'Living Room',
    hasPhotos: true,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 2,
  },
];

export const PolicyCheckInSteps = [
  {
    key: 'PolicyHolder',
    title: 'Policyholder Information',
    placeholderIcon: '/assets/policy/policy_holder.svg',
    categoryInstructions:
      'Enter the information for the primary policyholder. Please ensure the information here matches the details on your policy and drivers license.',
    hasPhotos: false,
    isInvalid: false,
    isValid: false,
  },
  {
    key: 'CoApplicant',
    title: 'Co-applicant Information',
    placeholderIcon: '/assets/policy/coapplicant.svg',
    categoryInstructions:
      'Enter the information of your co-applicant. Please ensure the information here matches the details on your policy.',
    hasPhotos: false,
    hasComment: false,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 1,
    gate: {
      question: 'Does this policy have a coapplicant?',
      type: 'boolean',
      value: false,
    },
  },
  {
    key: 'DeclarationForm',
    title: 'Declaration Form',
    sampleImage: '/assets/policy/dec_sample.png',
    placeholderIcon: '/assets/policy/declaration_form.svg',
    categoryInstructions: 'Select your Declaration Form or take a picture',
    hasPhotos: true,
    isInvalid: false,
    isValid: false,
    maxPhotos: 2,
    minPhotos: 1,
  },
  {
    key: 'InsuranceCard',
    title: 'Insurance Card',
    sampleImage: '/assets/policy/ins_card_sample.png',
    placeholderIcon: '/assets/policy/insurance_card.svg',
    categoryInstructions: 'Select your Insurance Card or take a picture',
    hasPhotos: true,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 1,
    gate: {
      question: 'Do you have an insurance card?',
      type: 'boolean',
      value: false,
    },
  },
  {
    key: 'DriversLicense',
    title: "Driver's License/ID Card",
    sampleImage: '/assets/policy/dl_sample.png',
    placeholderIcon: '/assets/policy/drivers_license.svg',
    categoryInstructions:
      "Please provide clear images of the front of your Driver's License/ID card.",
    hasPhotos: true,
    isInvalid: false,
    isValid: false,
    maxPhotos: 4,
    minPhotos: 1,
  },
];

export const PolicyCheckInTypes = [
  {
    id: 1,
    name: 'Auto',
    type: 'auto',
    placeholder: '/assets/policy/vehicle.svg',
  },
  {
    id: 2,
    name: 'Home',
    type: 'home',
    placeholder: '/assets/buyers/svg/datapoint_home.svg',
  },
  {
    id: 1,
    name: 'Home and Auto Bundle',
    type: 'bundle',
    placeholder: '/assets/policy/home_auto_bundle.svg',
  },
];

export const KYNAT_REFERRAL_CODE = 'KYNAT';
export const LOWTOR_REFERRAL_CODE = 'LOWTOR';

<template>
  <v-container class="customization-wrapper">
    <div class="kynat-header mb-2 d-flex justify-center align-center">
      <v-img
        max-width="100"
        contain
        src="https://parked.blob.core.windows.net/web/kynat/logo.png"
      />
      <StepOneSVG class="app-logo" :show-slogan="false" />
    </div>
    <h1 class="text-h4 font-weight-medium mb-2">
      Welcome {{ customer?.nameFirst }}
    </h1>
    <p class="mb-0">
      Follow the prompts and take a few photos of your home exterior to earn
      $10.
    </p>
    <p>Not home at the moment or bad weather? Just click ‘Remind me later’.</p>
    <EmptyData
      :time="5"
      :showBottomButtons="false"
      imgPath="assets/ownli/home_empty_state.png"
      :benefits="[
        'Home Check-In Reward: $10',
        'Additional check-ins (fireplace, alarm, etc.): Up to $10',
        'Have certified photos of your home condition in case of damages in the future.',
      ]"
    />
    <v-spacer></v-spacer>
    <v-btn x-large color="primary" @click="onStartNow">Start Now</v-btn>
  </v-container>
</template>
<script>
import EmptyData from '@/components/global/EmptyData.vue';
import StepOneSVG from '@/components/onboarding/StepOneSVG.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'KynatOnboarding',
  components: {
    EmptyData,
    StepOneSVG,
  },
  computed: {
    ...mapGetters(['customer']),
  },
  methods: {
    onStartNow() {
      localStorage.setItem('isCompletedReferral', true);
      this.$router.replace('/mobile-only');
    },
  },
};
</script>
<style scoped>
.customization-wrapper {
  max-width: 600px;
}
.app-logo {
  max-width: 100px;
  height: 45px;
  margin-top: 16px;
}
.kynat-header {
  gap: 16px;
}
</style>

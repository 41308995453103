<template>
  <div class="empty-data-container">
    <p class="d-flex align-center" v-if="time">
      <v-icon class="time-icon mr-1 opacity"
        >$vuetify.icons.global_clock_time_five</v-icon
      >
      Time to complete - about
      <span class="minutes ml-1"> {{ time }} minutes</span>
    </p>

    <h1 class="empty-title font-weight-medium">{{ header1 }}</h1>
    <p class="empty-sub-title">{{ header2 }}</p>

    <v-img :src="imgPath" max-height="280" class="mb-6 mt-6"></v-img>

    <p class="mt-4 mb-4 d-flex align-center font-weight-medium">
      <v-img
        width="28"
        max-width="28"
        class="mr-2"
        src="assets/ownli/benefit_empty_state.png"
      ></v-img>
      Your Benefits
    </p>

    <p class="mb-1" v-for="(item, index) in benefits" :key="index">
      <v-icon class="time-icon mr-1">$vuetify.icons.global_checkAll</v-icon>
      {{ item }}
    </p>
    <div v-if="showBottomButtons" class="mt-6">
      <v-btn
        x-large
        block
        color="primary"
        class="mb-4"
        @click="$router.replace('/mobile-only')"
      >
        Start adding data
      </v-btn>
      <v-btn x-large block color="primary" outlined> What you can earn </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyData',
  props: {
    time: {
      type: Number,
    },
    header1: {
      type: String,
    },
    header2: {
      type: String,
    },
    imgPath: {
      type: String,
    },
    benefits: {
      type: Array,
    },
    showBottomButtons: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.empty-data-container {
  max-width: 620px;
  width: 100%;
  margin: auto;
  margin-top: 32px;
}
</style>

<template>
  <v-card class="py-2">
    <SetUpDone
      successText="Check-in complete!"
      imageUrl="/assets/images/survey.png"
      title2="See your data buyers"
      subTitle="It's Important for these companies to transparently purchase YOUR data from YOU."
    />
    <!-- <v-row>
      <v-col align="center">
        <h1 class="mt-4 text-h3 text-uppercase primary--text">Yaaay!</h1>
        <div class="success--text my-2">
          <v-icon color="success">$vuetify.icons.check_circle</v-icon>
          Check-in complete!
        </div>
        <v-img class="check-in-img" src="/assets/images/survey.png"></v-img>
        <h5 class="text-h5 text-uppercase mt-4">See your data buyers</h5>
        <p class="darken-2 mt-4 mx-2">
          It's Important for these companies to transparently purchase YOUR data
          from YOU.
        </p>
      </v-col>
    </v-row> -->
    <v-card
      class="ma-3"
      v-for="(reward, index) in surveySubmitRewards"
      :key="'t' + index"
    >
      <v-list-item>
        <v-list-item-avatar size="50" class="rounded-0">
          <v-img :src="reward?.buyer?.listLogo"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6">{{
            reward?.buyer?.name
          }}</v-list-item-title>
          <v-list-item-subtitle class="text-wrap text-body-1">
            {{ reward?.buyer?.shortDescription }}
          </v-list-item-subtitle>
          <h4 class="mt-1 success--text">
            {{ getRewardAmount(reward) }}
          </h4>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-col align="center">
      <v-btn color="primary" class="d-block my-4" @click="navigate('/rewards')"
        >Go to my wallet</v-btn
      >
      <v-btn
        color="primary"
        class="d-block my-4"
        @click="navigate('/home-card')"
        >Go to my data</v-btn
      >
      <v-btn @click="socialShareModal = true">
        <v-icon class="mr-2">$vuetify.icons.actions_share_fill</v-icon>
        share with friends
      </v-btn>
    </v-col>
    <v-dialog v-model="socialShareModal" max-width="400">
      <v-card class="pa-4 pb-8" align="center">
        <h1 class="text-h5 mb-4">Share on</h1>
        <v-row no-gutters>
          <v-col>
            <ShareNetwork
              network="Email"
              :url="url"
              :title="title"
              :description="description"
            >
              <v-btn>
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </ShareNetwork>
          </v-col>
          <v-col>
            <ShareNetwork
              network="Facebook"
              :url="url"
              :title="title"
              :description="description"
            >
              <v-btn>
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
            </ShareNetwork>
          </v-col>
          <v-col>
            <ShareNetwork
              network="WhatsApp"
              :url="url"
              :title="title"
              :description="description"
            >
              <v-btn>
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </ShareNetwork>
          </v-col>
          <v-col>
            <ShareNetwork
              network="Skype"
              :url="url"
              :title="title"
              :description="description"
            >
              <v-btn>
                <v-icon>mdi-skype</v-icon>
              </v-btn>
            </ShareNetwork>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import SetUpDone from '@/components/global/SetUpDone.vue';
export default {
  name: "SurveyDone",
  components: {
    SetUpDone
  },  
  data() {
    return {
      socialShareModal: false,
      url: "https://www.ownli.co",
      title: "Know your worth",
      description:
        "Hey, I just earned money with Ownli. You can too...it’s easy. Join us on https://www.ownli.co",
    };
  },
  methods: {
    getRewardAmount(reward) {
      let amount = 0;
      if (reward?.discounts?.length) {
        reward.discounts.forEach((r) => {
          amount += r.amount;
        });
      }
      return "$" + amount;
    },
    navigate(url) {
      this.$router.replace(url);
    },
  },
  props: {
    surveySubmitRewards: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style scoped>
.check-in-img {
  max-width: 150px;
}
.darken-2 {
  color: #616161;
}
.lh-18 {
  line-height: 24;
}
</style>

<template>
  <v-container class="customization-wrapper">
    <SetUpDone
      successText="SYNC INITIATED!"
      imageUrl="assets/ownli/service_history.png"
      title2="You're all set"
      subTitle="Please allow 1 business day for the reward to be processed."
    />
    <v-spacer></v-spacer>
    <v-btn x-large color="primary" class="mb-2" to="/marketplace"
      >Go To Marketplace</v-btn
    >
    <v-btn x-large outlined color="primary" to="/home-card"
      >Go To My Data</v-btn
    >
  </v-container>
</template>
<script>
import SetUpDone from '@/components/global/SetUpDone.vue';

export default {
  name: 'DataContributorOnboardingStep3',
  components: {
    SetUpDone,
  },
};
</script>

<style lang="scss">
.check-in-img {
  max-width: 100% !important;
}
</style>

<template>
  <v-container class="customization-wrapper">
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <template v-else>
      <DataContributorHeader
        :contributorImg="buyerData?.listLogo"
        :isRoundImg="customer.referalCode?.toLowerCase() !== 'lowtor'"
      />
      <template v-if="customer.referalCode?.toLowerCase() === 'lowtor'">
        <div class="contributor-details">
          <p class="text-h6 mb-0 text-uppercase">
            Welcome {{ customer?.nameFirst }}!
          </p>
          <p class="text-body-1">
            Your service history at Low Tor is valuable data. Sync and earn from
            it with Ownli.
          </p>
        </div>
        <div class="contributor-details">
          <p class="text-h6 mb-0 text-uppercase">DATA IS AN ASSET</p>
          <p class="text-body-1">
            Ownli helps you manage your data and harness its earning potential.
          </p>
        </div>
        <div class="contributor-details">
          <p class="text-h6 mb-0 text-uppercase">Your Service Check-in</p>
          <p class="text-body-1">
            Sync your Low Tor service today to start earning. Earn $1 every
            time you visit and sync.
          </p>
        </div>
        <div class="contributor-details">
          <p class="text-h6 mb-0 text-uppercase">Full control & Security</p>
          <p class="text-body-1">
            You control what data you add and who can buy it. That means ​no
            spam lists or tracking.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="contributor-details">
          <p class="text-h6 mb-0 text-uppercase">
            {{ getHeader1 }}
          </p>
          <p class="text-body-1">
            {{ buyerData?.contributorOnboardingStep1Subtext1 }}
          </p>
        </div>
        <div class="contributor-details">
          <p class="text-h6 mb-0 text-uppercase">
            {{ buyerData?.contributorOnboardingStep1Header2 }}
          </p>
          <p class="text-body-1">
            {{ buyerData?.contributorOnboardingStep1Subtext2 }}
          </p>
        </div>
        <div class="contributor-details">
          <p class="text-h6 mb-0 text-uppercase">
            {{ buyerData?.contributorOnboardingStep1Header3 }}
          </p>
          <p class="text-body-1">
            {{ buyerData?.contributorOnboardingStep1Subtext3 }}
          </p>
        </div>
        <div class="contributor-details">
          <p class="text-h6 mb-0 text-uppercase">
            {{ buyerData?.contributorOnboardingStep1Header4 }}
          </p>
          <p class="text-body-1">
            {{ buyerData?.contributorOnboardingStep1Subtext4 }}
          </p>
        </div>
      </template>

      <v-spacer></v-spacer>
      <v-btn
        x-large
        color="primary"
        @click="onContinue"
        :disabled="isSubmitting"
        >Continue</v-btn
      >
    </template>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import api from '@/services/api-service';
import BrandLogo from '@/components/appshell/BrandLogo.vue';
import { updateTOSAnswer } from '../services/app.service';
import DataContributorHeader from '@/components/onboarding/DataContributorHeader.vue';

export default {
  name: 'DataContributorOnboardingStep1',
  components: {
    BrandLogo,
    DataContributorHeader,
  },
  data() {
    return {
      loading: true,
      buyerData: null,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters(['customer']),
    getHeader1() {
      return this.buyerData?.contributorOnboardingStep1Header1?.replaceAll(
        '[FIRST_NAME]',
        this.customer?.nameFirst
      );
    },
  },
  mounted() {
    this.loadDetails();
  },
  methods: {
    async loadDetails() {
      try {
        const response = await api.get(
          `data-buyers/${this.customer.contributorId}`
        );
        this.buyerData = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async onContinue() {
      if (this.customer?.referalCode?.toLowerCase() === 'lowtor') {
        this.$router.replace('/data-contributor-2');
        return;
      }
      try {
        this.isSubmitting = true;
        await updateTOSAnswer(this.customer.id, this.buyerData.id, 1);
        localStorage.setItem('isCompletedReferral', true);
        this.$router.replace('/home-card');
      } catch (error) {
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customization-wrapper {
  max-width: 600px;
}
</style>

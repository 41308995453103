
<template>
  <v-container>
    <p class="text-body-1 mt-4">
      These are potential data buyers based on your region.
    </p>
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-list v-else color="transparent">
      <v-card
        class="mb-3 pb-3"
        v-for="(buyer, index) in sponsors"
        :key="'r' + index"
      >
        <v-list-item>
          <v-list-item-avatar size="50" class="rounded-0">
            <v-img :src="buyer?.buyer?.listLogo" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6 font-weight-bold">{{
              buyer?.buyer?.name
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-wrap text-body-1">
              {{ buyer?.buyer?.shortDescription }}
            </v-list-item-subtitle>
            <a @click="openBuyerModal(buyer.buyer.id)" class="mt-1 primary--text text-capitalize font-weight-medium">
              Learn more & see earnings
            </a>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-list>
    <!-- learn more modal -->
    <v-dialog v-if="activeBuyerId" v-model="buyerModal" fullscreen hide-overlay>
      <buyer-details
        @dismiss="dismiss"
        :buyerId="activeBuyerId"
      ></buyer-details>
    </v-dialog>
  </v-container>
</template>
<script>
import RewardCard from "@/components/customer/RewardCard";
import { mapGetters } from "vuex";

import api from "@/services/api-service";
import BuyerDetails from "../components/customer/BuyerDetails.vue";
export default {
  name: "Marketplace.vue",
  components: {
    RewardCard,
    BuyerDetails,
  },
  computed: {
    ...mapGetters(["customer"]),
  },
  data() {
    return {
      loading: true,
      buyerModal: false,
      sponsors: [],
      activeBuyerId: null,
    };
  },
  methods: {
    async loadData() {
      try {
        const url = `customers/${this.customer.id}/discounts`;
        const response = await api.get(url, {
          requestId: url,
        });
        this.sponsors = response.data ?? [];
      } catch (error) {
        console.error("Error fetching survey templates:", error);
      } finally {
        this.loading = false;
      }
    },
    openBuyerModal(buyerId) {
      this.activeBuyerId = buyerId;
      this.buyerModal = true;
    },
    dismiss() {
        this.activeBuyerId = null;
        this.buyerModal = false;
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>
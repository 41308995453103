<template>
  <v-container>
    <h2>Good {{ greeting() }}</h2>
    <p>
      This is the home of all your valuable data. Tap a card to add your data
      and start earning!
    </p>
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0" v-else>
      <v-col v-for="(dataPoint, index) in dataPoints" :key="index">
        <RewardCard
          @onClickBtn="navigate(dataPoint)"
          :title="dataPoint.name"
          :rewardImg="dataPoint.icon"
          :rewardAmount="dataPoint.reward"
          :buttonText="dataPoint.buttonText"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RewardsCarSVG from '@/components/customer/RewardsCarSVG';
import RewardCard from '@/components/customer/RewardCard';
import api from '@/services/api-service';
import { mapGetters } from 'vuex';
export default {
  name: 'HomeCard',
  components: {
    RewardsCarSVG,
    RewardCard,
  },
  computed: {
    ...mapGetters(['customer']),
  },
  data() {
    return {
      dataPoints: [
        {
          name: 'Vehicle check-in',
          logo: 'logo1.png',
          category: 'Category 1',
          disabled: false,
          icon: 'assets/buyers/svg/datapoint_vehicle.svg',
          type: 'Mileage',
          reward: '0.00+',
          buttonText: 'Unlock on App',
          path: "/my-vehicles"
        },
        {
          name: 'Insurance Policy Check-in',
          logo: 'logo2.png',
          category: 'Category 2',
          disabled: true,
          icon: 'assets/buyers/svg/datapoint_policy.svg',
          type: 'Policy',
          reward: '0.00+',
          buttonText: 'Unlock on App',
          path: "/my-policies"
        },
        {
          name: 'Property Check-in',
          logo: 'logo2.png',
          category: 'Category 3',
          disabled: true,
          icon: 'assets/buyers/svg/datapoint_home.svg',
          type: 'Home',
          reward: '0.00+',
          buttonText: 'Unlock on App',
          path: "/my-homes"
        },
        {
          name: 'Surveys',
          logo: 'logo2.png',
          category: 'Category 4',
          disabled: true,
          icon: 'assets/buyers/svg/datapoint_survey.svg',
          type: 'Surveys',
          reward: '0.00+',
          buttonText: 'Unlock',
          path: "/surveys"
        },
      ],
      loading: true,
      checkinSummary: [],
      discounts: [],
    };
  },

  methods: {
    async loadData() {
      try {
        await this.getDiscounts();
        await this.getCheckInSummary();
        this.dataPoints?.forEach((d) => {
          let amount = 0;
          this.discounts?.forEach((e) => {
            if (d.type === 'Mileage') {
              const result = this.getMilageReward(e);
              amount += result?.amount ?? 0;
            } else if (d.type === 'Home') {
              const result = this.getPropertyCheckInReward(e);
              amount += result?.amount ?? 0;
            } else if (d.type === 'Policy') {
              const result = this.getConnectedPolicyCheckinReward(e);
              amount += result?.amount ?? 0;
            } else if (d.type === 'Surveys') {
              const result = this.getSurveyCompletedReward(e);
              amount += result?.amount ?? 0;
            }
            d.reward = amount.toFixed(2) + '+';
          });
          const count = this.getCheckinCount(d.type);
          if (count > 0) {
            d.buttonText = 'Active';
          }
        });
      } catch (error) {
        console.log('Error', error);
      } finally {
        this.loading = false;
      }
    },
    async getDiscounts() {
      const url = `customers/${this.customer.id}/discounts`;
      const response = await api.get(url, {
        requestId: url,
      });
      this.discounts = response.data ?? [];
    },
    async getCheckInSummary() {
      const url = `customers/${this.customer.id}/checkin-summary`;
      const response = await api.get(url, {
        requestId: url,
      });
      this.checkinSummary = response.data;
    },
    getMilageReward(discount) {
      const enrollments = discount?.discounts?.filter(
        (e) =>
          (e.type == 'Enrollment' || e.type == 'Activation') &&
          (e.vertical == 'Vehicle Mileage' || e.vertical == 'VehicleMileage')
      );
      return enrollments.length > 0 ? enrollments[0] : null;
    },
    getPropertyCheckInReward(discount) {
      const checkins = discount?.discounts?.filter(
        (e) =>
          e.type == 'CheckIn' &&
          (e.vertical == 'Property External Inspection' ||
            e.vertical == 'PropertyExternalInspection')
      );
      return checkins.length > 0 ? checkins[0] : null;
    },
    getConnectedPolicyCheckinReward(discount) {      
      const checkins = discount?.discounts?.filter(
        (e) =>
          e.type == 'ConnectedPolicyCheckin' &&
          (e.vertical == 'Insurance Policy' || e.vertical == 'InsurancePolicy')
      );
      return checkins.length > 0 ? checkins[0] : null;
    },
    getSurveyCompletedReward(discount) {
      const checkins = discount?.discounts?.filter(
        (e) => e?.rewardType !== null && e?.rewardType?.includes('Survey')
      );
      return checkins.length > 0 ? checkins[0] : null;
    },
    getCheckinCount(type) {
      const names = [];
      if (type == 'Mileage') {
        names.push('VehicleCheckinCount');
      } else if (type == 'Home') {
        names.push('PropertyCheckinCount');
      } else if (type == 'Policy') {
        names.push('InsurancePolicyCheckinCount');
        names.push('ConnectedInsurancePolicyCheckinCount');
      } else if (type == 'Surveys') {
        names.push('SurveyCount');
      }
      return (
        this.checkinSummary?.reduce(
          (count, e) =>
            count + (names?.some((d) => d === e.name) ? e.checkinCount : 0),
          0
        ) || 0
      );
    },
    greeting() {
      const hour = new Date().getHours();
      if (hour < 12) {
        return "Morning";
      }
      if (hour < 17) {
        return "Afternoon";
      }
      return "Evening";
    },
    navigate(dataPoint) {
      const count = this.getCheckinCount(dataPoint.type);
      if (count > 0 || dataPoint.type == 'Surveys') {
        this.$router.push(dataPoint?.path);
      } else {
        this.$router.push("/mobile-only");
      }
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<template>
  <div>
    <StepOneSVG class="app-logo" :show-slogan="false" />
    <div class="data-contributor-img mb-8 mt-4 d-flex justify-center">
      <v-avatar class="contributor-image" v-if="isRoundImg" width="80" height="80">
        <v-img :src="contributorImg"></v-img>
      </v-avatar>
      <v-img
        v-else
        width="80"
        max-width="80"
        contain
        class="contributor-image"
        :src="contributorImg"
      ></v-img>
      <v-avatar width="80" height="80">
        <BrandLogo />
      </v-avatar>
    </div>
  </div>
</template>

<script>
import StepOneSVG from './StepOneSVG.vue';

export default {
  name: 'DataContributorHeader',
  components: {
    StepOneSVG,
  },
  props: {
    contributorImg: {
      type: String,
      required: true,
    },
    isRoundImg: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.app-logo {
  width: 100px;
  height: 50px;
}
.contributor-image {
  margin-right: -20px;
  z-index: 11;
}
</style>

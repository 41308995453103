<template>
  <v-container>
    <v-card class="mb-6">
      <v-card-title class="subtitle-1 font-weight-regular">
        {{ home?.address?.address }}
        <v-chip class="status-badge text-capitalize ml-2 font-weight-medium" color="primary">
          {{ getFormattedCheckInStatus }}
        </v-chip>
      </v-card-title>

      <!-- <v-card-text>
        Go over all the sections of your home check-in. Each section needs to
        have at least one photo.
      </v-card-text> -->
    </v-card>
    <v-card>
      <v-card-text>
        <template v-for="step in steps">
          <div class="mb-4" :key="step.key">
            <div class="mb-2 text-subtitle-1 font-weight-medium">
              {{ step.title }}
            </div>

            <div
              class="step-grid-wrapper"
              v-if="propertyImages[step.key]?.length > 0"
            >
              <button
                class="step-img-wrapper"
                v-for="img in propertyImages[step.key]"
                :key="img.id"
                :disabled="loadingImages.includes(img?.id)"
                @click="openImgPreview(img.photoUrl)"
              >
                <v-overlay
                  class="img-overlay"
                  :value="loadingImages.includes(img?.id)"
                >
                  <v-progress-circular
                    indeterminate
                    size="34"
                  ></v-progress-circular>
                </v-overlay>
                <v-img
                  max-height="200"
                  height="150"
                  width="150"
                  :src="img.photoUrl"
                ></v-img>
                <!-- <template v-if="getFormattedCheckInStatus === 'In Progress'">
                  <v-btn
                    v-if="img.photoUrl && !img.synced"
                    class="image-action-icon cloud-icon"
                    icon
                    rounded
                    @click.stop="uploadPhoto(step.key, img)"
                  >
                    <v-icon color="accent"
                      >$vuetify.icons.global_cloud_off</v-icon
                    >
                  </v-btn>
                  <v-icon
                    v-if="img.synced"
                    class="image-action-icon cloud-check"
                    color="primary"
                    >$vuetify.icons.global_cloud_check</v-icon
                  >
                </template> -->
              </button>
            </div>
            <div v-else>No Photos.</div>
          </div>
        </template>
      </v-card-text>
      <ImageViewerDialog
        :isOpen="dialog"
        :imgUrl="previewImg"
        @onClose="dialog = false"
      />
      <ConfirmationDialog
        :isOpen="showSyncErrorDialog"
        title="Error syncing photo to cloud"
        bodyText="Don't worry. All your data is cached on your browser. You will get a chance to sync your pictures before submitting your checkin."
        confirmButton="Continue"
        @onConfirm="showSyncErrorDialog = false"
      />
    </v-card>

    <!-- <div
      v-if="getFormattedCheckInStatus === 'In Progress'"
      class="mt-6 mb-16 d-flex justify-space-between align-center"
    >
      <v-btn
        color="primary"
        class="text-capitalize button"
        :disabled="isImagesSynced || isSyncAll"
        @click="syncAllImages()"
      >
        Sync Photos
      </v-btn>
      <v-btn
        color="primary"
        class="text-capitalize button"
        :disabled="!isImagesSynced"
        @click="submitPropertyCheckIn()"
      >
        Submit For Review
      </v-btn>
    </div> -->
    <!-- <v-footer v-if="getFormattedCheckInStatus === 'In Progress'" app outlined>
      <div class="check-in-footer d-flex justify-space-between align-center">
        <v-btn
          color="primary"
          class="text-capitalize button"
          :disabled="isImagesSynced || isSyncAll"
          @click="syncAllImages()"
        >
          Sync Photos
        </v-btn>
        <v-btn
          color="primary"
          class="text-capitalize button"
          :disabled="!isImagesSynced"
          @click="submitPropertyCheckIn()"
        >
          Submit For Review
        </v-btn>
      </div>
    </v-footer> -->
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/services/api-service';
import { set } from 'vue';
import ImageViewerDialog from '@/components/global/ImageViewerDialog.vue';
import ConfirmationDialog from '@/components/global/ConfirmationDialog.vue';
import { getCurrentPosition } from '../services/app.service';

export default {
  name: 'HomeCheckInPreview',
  components: {
    ImageViewerDialog,
    ConfirmationDialog,
  },
  computed: {
    ...mapGetters(['customer', 'properties', 'pendingHomeCheckIns']),
    isImagesSynced() {
      return Object.values(this.propertyImages)
        .flatMap((e) => e)
        .every((e) => e.synced);
    },
    getFormattedCheckInStatus() {
      if (this.checkInStatus === 'pending') {
        return 'In Review';
      } else if (this.checkInStatus === 'onhold') {
        return 'On Hold';
      }
      return this.checkInStatus || "In Progress";
    },
    checkInStatus() {
      return this.checkIn?.status?.toLowerCase();
    },
  },
  data() {
    return {
      home: null,
      dialog: false,
      showSyncErrorDialog: false,
      previewImg: '',
      propertyImages: {},
      loadingImages: [],
      isSyncAll: false,
      isSubmitting: false,
      checkIn: null,
      steps: [
        {
          key: 'ProofOfAddress',
          title: 'Proof of Address',
        },
        {
          key: 'Front',
          title: 'Front of house',
        },
        {
          key: 'Back',
          title: 'Back of house',
        },
        {
          key: 'Right',
          title: 'Right side of house',
        },
        {
          key: 'Left',
          title: 'Left side of house',
        },
        {
          key: 'OutdoorsShed',
          title: 'Outdoor Structures',
        },
        {
          key: 'Pool',
          title: 'Pool',
        },
      ],
    };
  },
  beforeMount() {
    this.defaultSteps = this.$route.meta['defaultSteps'];
  },
  mounted() {
    this.home = this.properties.find((e) => e?.id === this.$route.params['id']);
    console.log(JSON.parse(JSON.stringify(this.home)));
    this.checkIn = this.home?.checkins?.find(
      (e) => e.id === this.$route.query['checkIn']
    );
    if (this.checkIn) {
      this.checkIn?.steps?.forEach((e) =>
        set(
          this.propertyImages,
          e.type,
          e.photos?.map((e) => ({
            id: e.id,
            photoUrl: e.photoUrl,
            synced: true,
            name: e.fileName,
          }))
        )
      );
    } else {
      // this.propertyImages = this.pendingHomeCheckIns[this.home.id];
    }
  },
  methods: {
    openImgPreview(imgPath) {
      this.previewImg = imgPath;
      this.dialog = true;
    },
    async syncAllImages() {
      try {
        this.isSyncAll = true;
        const promises = [];
        Object.entries(this.propertyImages).forEach(([key, value]) => {
          const unSyncedImages = value.filter((e) => !e.synced);
          if (unSyncedImages?.length > 0) {
            unSyncedImages.forEach((image) =>
              promises.push(this.uploadPhoto(key, image))
            );
          }
        });
        await Promise.all(promises);
      } finally {
        this.isSyncAll = false;
      }
    },
    async uploadPhoto(uploadType, image) {
      try {
        this.loadingImages.push(image?.id);
        const uploadPacket = {
          fileUploadType: uploadType,
          fileName: image?.name,
          base64: image?.photoUrl?.split(',')[1],
        };
        const url = `customers/${this.customer.id}/upload-file`;
        const response = await api.post(url, uploadPacket);
        if (response.data.storageUri) {
          this.updateLocalPhoto(uploadType, image);
        }
      } catch (error) {
        this.showSyncErrorDialog = true;
      } finally {
        this.loadingImages.shift();
      }
    },
    updateLocalPhoto(uploadType, image, synced = true) {
      let prevImages = JSON.parse(
        JSON.stringify(this.propertyImages[uploadType])
      );
      prevImages = prevImages.map((e) =>
        e.id === image.id
          ? {
              ...e,
              synced: synced,
              img: image.photoUrl,
            }
          : e
      );
      set(this.propertyImages, uploadType, prevImages);
      this.$store.commit('SET_PENDING_HOME_CHECK_INS', {
        id: this.home.id,
        key: uploadType,
        data: prevImages,
      });
    },
    async submitPropertyCheckIn() {
      try {
        this.isSubmitting = true;
        const { coords } = await getCurrentPosition();
        const uploadPacket = {
          propertyId: this.home.id,
          verticalId: 'VERT_A2QFWOSJHGH7',
          longitude: coords?.longitude,
          latitude: coords?.latitude,
          steps: [],
        };
        this.steps.forEach((step) => {
          if (this.propertyImages[step.key].length > 0) {
            const uploadStep = {
              type: step.key,
              photos: this.propertyImages[step.key].map((photo, i) => ({
                photoUrl: photo.photoUrl,
                deviceMake: photo.deviceMake,
                deviceModel: photo.deviceModel,
                timestampOriginal: photo.timestamp,
                timestampModified: photo.timestamp,
                fileSource: 'camera',
                longitude: photo.longitude,
                latitude: photo.latitude,
              })),
            };
            uploadPacket.steps.push(uploadStep);
          }
        });
        console.log('uploadPacket', uploadPacket);
        return;
        const response = await api.post(
          `properties/${this.home.id}/check-in`,
          uploadPacket
        );
      } catch (error) {
        console.log('submitPropertyCheckIn Error', error);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style scoped>
.image-action-icon {
  position: absolute;
}
.image-action-icon {
  bottom: 8px;
}
.cloud-icon {
  top: 4px;
  right: 4px;
}
.status-badge {
  height: 26px;
}
.step-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  gap: 16px;
}
.step-img-wrapper {
  position: relative;
}
.cloud-check {
  height: fit-content;
  top: 8px;
  right: 8px;
}
.img-overlay {
  position: absolute;
}
.check-in-footer {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}
</style>

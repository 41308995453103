<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn color="primary" text @click="dismiss"> Close </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row v-if="!buyerDiscount" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <template v-else>
      <video v-if="buyerDiscount?.buyer?.videoUrl"
        class="video-wrapper"
        :src="buyerDiscount?.buyer?.videoUrl"
        muted
        controls
        autoplay
      ></video>
      <v-img v-else class="detail-img" contain :src="buyerDiscount?.buyer?.logo"></v-img>
      <v-tabs v-model="currentTab" grow>
        <v-tab :ripple="false"> Details </v-tab>
        <v-tab :ripple="false"> Earnings </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item class="pa-6" v-html="buyerDiscount?.buyer?.description">
        </v-tab-item>
        <v-tab-item class="pa-2">
          <v-simple-table class="borderless-table" v-if="showEarningTable">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="min-width: 40%"
                    class="primary--text text-h6 font-weight-bold"
                  >
                    Type
                  </th>
                  <th class="primary--text text-h6 font-weight-bold">Price</th>
                  <th class="primary--text text-h6 font-weight-bold">Terms</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in earnings">
                  <template v-if="item?.discounts?.length">
                    <tr class="d-block mt-4" :key="'i' + index">
                      <th class="primary--text text-h6 font-weight-bold">
                        {{ item.name }}
                      </th>
                    </tr>
                    <template v-for="(earning, idx) in item.discounts">
                      <tr :key="'e' + idx + index">
                        <td>
                          {{ earning?.rewardTypeDescription }}
                        </td>
                        <td>{{ earning.amountFormatted }}</td>
                        <td>{{ earning?.terms }}</td>
                      </tr>
                      <tr :key="'extra' + idx + index" v-if="earning?.note">
                        <td colspan="3" class="text-break">
                          {{ earning.note }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </template>
          </v-simple-table>
          <div class="mt-4 mx-4"
            v-if="buyerDiscount?.buyer?.earningsDescription"
            v-html="buyerDiscount.buyer.earningsDescription"
          ></div>
          <p class="text-center mt-4" v-if="buyerDiscount?.discounts?.length">{{ buyerDiscount?.discounts[0]?.offerDescription }}</p>
          <p class="text-center mt-2 opacity" v-if="showPolicyNote">** Ownli users can add a new active policy when the current one expires.</p>
          <v-row v-if="buyerDiscount?.buyer?.shopNowLink">
            <v-col align="center">
              <v-btn
                target="_blank"
                :href="buyerDiscount.buyer.shopNowLink"
                class="mt-4"
                color="primary"
                >Shop now</v-btn
              >
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/services/api-service";
export default {
  name: "BuyerModal",
  computed: {
    ...mapGetters(["customer"]),
    showEarningTable() {
      const find = this.earnings.find((e) => e.discounts?.length);
      return !!find;
    },
    showPolicyNote() {
      return this.earnings[2]?.discounts?.length;
    }
  },
  data() {
    return {
      currentTab: "details",
      earnings: [],
      buyerDiscount: null,
    };
  },
  props: ["buyerId", "buyerData"],
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },
    async getDiscount() {
      const url = `data-buyers/${this.$props.buyerId}/discounts?state=${this.customer.state}&customerId=${this.customer.id}`;
      const response = await api.get(url, {
        requestId: url,
      });
      console.log("response", response);
      if (response?.data?.length) {
        this.buyerDiscount = response.data[0];
        this.populateDiscountsByCondition();
      }
    },
    populateDiscountsByCondition() {
      this.earnings = [
        {
          name: "Vehicle",
          discounts: [],
        },
        {
          name: "Property",
          discounts: [],
        },
        {
          name: "Policy",
          discounts: [],
        },
        {
          name: "Survey",
          discounts: [],
        },
        {
          name: "Purchase",
          discounts: [],
        },
      ];
      this.buyerDiscount.discounts.forEach((item) => {
        if (
          (item.type === "Enrollment" || item.type === "Activation") &&
          (item.vertical === "Vehicle Mileage" ||
            item.vertical === "VehicleMileage")
        ) {
          if (!item.rewardTypeDescription) {
            item.rewardTypeDescription = "Mileage";
          }

          if (this.buyerDiscount.campaign.rules[0].throttleValue) {
            item.terms =
              "Monthly/Up to " +
              this.buyerDiscount.campaign.rules[0].throttleValue;
          } else {
            item.terms = "One-time";
          }
          this.earnings[0].discounts.push(item); // Vehicle category
        } else if (
          item.type === "CheckIn" &&
          (item.vertical === "Property External Inspection" ||
            item.vertical === "PropertyExternalInspection")
        ) {
          if (!item.rewardTypeDescription) {
            item.rewardTypeDescription = "Home";
          }
          item.terms = "One-time";
          this.earnings[1].discounts.push(item); // Property category
        } else if (
          item.vertical === "Insurance Policy" ||
          item.vertical === "InsurancePolicy"
        ) {
          if (item.type === "CheckIn") {
            item.rewardTypeDescription = "Manual Add";
          } else {
            item.rewardTypeDescription = "Sync";
          }
          item.terms = "Per policy term**";
          this.earnings[2].discounts.push(item); // Policy category
        } else if (
          item.rewardType !== null &&
          item.rewardType.includes("Survey")
        ) {
          item.terms = "One-time";
          this.earnings[3].discounts.push(item); // Survey category
        } else if (item.rewardType === "Purchase") {
          if (!item.rewardTypeDescription) {
            item.rewardTypeDescription = "Confirmation of Purchase*";
          }
          item.terms = "One-time";
          this.earnings[4].discounts.push(item);
        }
      });
    },
  },
  watch: {
    buyerData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.buyerDiscount = newValue;
          this.populateDiscountsByCondition();
        }
      },
    },
  },
  mounted() {
    if (this.$props?.buyerId) {
      this.getDiscount();
    }
  },
};
</script>
<style scoped>
.video-wrapper {
  width: 100%;
  max-height: 250px;
}
.borderless-table th,
.borderless-table td,
.borderless-table tr {
  border-bottom: none !important;
}
.borderless-table tbody th,
.borderless-table tbody td,
.borderless-table tbody tr {
  height: auto !important;
}
.fs-18 {
  font-size: 18px;
}
.text-break {
  white-space: break-spaces;
}
.detail-img {
  max-width: 600px;
  max-height: 250px;
  margin: auto;
}
</style>
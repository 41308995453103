<template>
    <v-app :class="{'primary-is-light':primaryIsLight }">
        <vue-page-transition name="fade-in-down">
            <router-view name="top_nav" class="router-view" v-model="drawer" />
        </vue-page-transition>

        <router-view name="connection_banner" />

        <router-view name="drawer_nav" class="router-view" v-model="drawer" />

        <v-main>
            <vue-page-transition :name="transitionName">
                <router-view class="router-view" />
            </vue-page-transition>
        </v-main>

        <vue-page-transition name="fade-in-up">
            <router-view name="bottom_nav" class="router-view" />
        </vue-page-transition>

        <Notifications />

        <!-- <RebrandPopUp/> -->
    </v-app>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import api from "@/services/api-service";

    import RebrandPopUp from "@/components/global/RebrandPopUp";
    import Notifications from "@/components/appshell/SysNotifications";
    import UpgradeVersion from "@/components/appshell/UpgradeVersion";
    import partners from "@/data/partners";
import { showCustomBuyerExperience } from './services/app.service';
import { KYNAT_REFERRAL_CODE } from './data/constants';

    export default {
        name: 'App',

        components: {
            RebrandPopUp,

            Notifications,
            UpgradeVersion
        },

        data() {
            return {
                versionDialog: false,
                drawer: this.$vuetify.breakpoint.lgAndUp,
                transitionName: "fade-in-left",
            }
        },

        computed: {
            ...mapGetters(["device", "connection", "offline", "updateInProgress", "user", "partner", "customer"]),
            isWeakSignal() {
                return this.connection != "3g" && this.connection != "4g";
            },

            primaryIsLight() {
                return this.partner != null && this.partner.darkPrimary == false;
                // return this.partner == null || this.partner != null && this.partner.darkPrimary == false;
            },

            host() {
                const requested = this.$route.query && this.$route.query.sub;
                if (requested) {
                    return `${requested}.joinparked.com`;
                }

                const host = document.location.host == "localhost:8080" ? "app.joinparked.com" : document.location.host;
                return host;
            }
        },

        watch: {
            updateInProgress(v, ov) {
                if (v) {
                    this.$Logger.track(`pwa_update`, {
                        component: this.$options.name,
                    });
                    this.$router.push("/pwa-update");
                }
            },
            connection(o, ov) {
                this.$Logger.track(`signal_change`, {
                    component: this.$options.name,
                    connection: o
                });

                if (o != "3g" && o != "4g") {
                    this.$Logger.track(`weak_signal`, {
                        component: this.$options.name,
                        connection: o
                    });
                }
            },
            offline(o, ov) {
                if (o) {
                    this.$Logger.track(`offline`, {
                        component: this.$options.name,
                    });
                }
            },
            user(u, ov) {
                if (u) {
                    const email = u.email;
                    const phone = u.phone;

                    if (email != null && email != "" || phone != null && phone != "") {
                        this.$Logger.identify(u.uid, {
                            component: this.$options.name,
                            email: email || 'N/A',
                            phone: phone || 'N/A',
                            name: u.name
                        });
                        this.$Logger.track(`login`, {
                            component: this.$options.name,
                            email: email || 'N/A',
                            phone: phone || 'N/A',
                            method: u.provider
                        });
                        this.loadCustomer(email, phone);
                    } else {
                        this.$store.commit("ADD_NOTIFICATION", {
                            text: "There is no E-mail/Phone associated with this account.Try a different login method.",
                            timeout: 8000,
                            multiLine: true
                        })
                        this.$store.commit('UPDATE_USER', null);
                    }
                } else {
                    this.$Logger.track(`logout`, {
                        component: this.$options.name,
                        email: ov && ov.email
                    });
                    this.$router.replace("/login");
                }
            },
            partner(v, ov) {
                if (v) {
                    this._setTheme(v);
                    this._setDynamicManifest();
                }
            },
            '$route'(to, from) {
                const toDepth = to.path.split('/').length
                const fromDepth = from.path.split('/').length
                this.transitionName = toDepth < fromDepth ? 'fade-in-right' : 'fade-in-left'
            }
        },

        methods: {
            _setDynamicManifest() {
                //used https://www.pwabuilder.com/imageGenerator to generate pwa resources:

                const host_sub = this.host.split(".")[0];
                const isDefaultTheme = this.host == "www.ownli.app" || "ownli.app";
                const base_url = isDefaultTheme ? 
                    "https://parked.blob.core.windows.net/ownli/www" :
                    `https://parked.blob.core.windows.net/web/${host_sub}`;

                const manifestURL = `${base_url}/manifest.json`;
                const fabiconURL = `${base_url}/icons/favicon.ico`;
                const fabicon32URL = `${base_url}/icons/32.png`;
                const fabicon16URL = `${base_url}/icons/16.png`;
                const appleIconURL = `${base_url}/icons/144.png`;
                const color = this.$vuetify.theme.themes.light.primary;

                document
                    .querySelector('link[rel="manifest"]')
                    .setAttribute("href", manifestURL);

                document
                    .querySelector('link[rel="icon"]')
                    .setAttribute("href", fabiconURL);
                document
                    .querySelector('link[rel="icon"][sizes="32x32"]')
                    .setAttribute("href", fabicon32URL);
                document
                    .querySelector('link[rel="icon"][sizes="16x16"]')
                    .setAttribute("href", fabicon16URL);
                document
                    .querySelector('link[rel="apple-touch-icon"]')
                    .setAttribute("href", appleIconURL);


                document
                    .querySelector('meta[name="theme-color"]')
                    .setAttribute("content", color);

                document
                    .querySelector('meta[name="msapplication-TileImage"]')
                    .setAttribute("content", appleIconURL);
                document
                    .querySelector('meta[property="og:image"]')
                    .setAttribute("content", appleIconURL);

                document
                    .querySelector('meta[property="og:url"]')
                    .setAttribute("content", document.location.origin);

                if (this.partner) {
                    document
                        .querySelector('meta[property="og:site_name"]')
                        .setAttribute("content", this.partner.name);
                }
            },
            _setTheme(v) {
                // Light theme
                this.$vuetify.theme.themes.light.primary = v.primary;
                this.$vuetify.theme.themes.light.secondary = v.secondary;
                this.$vuetify.theme.themes.light.accent = v.accent;
                // Dark theme
                this.$vuetify.theme.themes.dark.primary = v.primary;
                this.$vuetify.theme.themes.dark.secondary = v.secondary;
                this.$vuetify.theme.themes.dark.accent = v.accent;
            },
            _setPartner() {
                const partner = partners.find(p => p.host == this.host);
                if (partner) this.$store.commit("UPDATE_PARTNER", partner);
            },
            _setUtmParams() {
                if (this.$route.query && localStorage) {
                    for (const [key, value] of Object.entries(this.$route.query)) {
                        // console.log(`${key}: ${value}`);
                        localStorage.setItem(key, value);
                    }
                }
            },
            _setTrafficRef() {
                const lp = localStorage.getItem("landed");
                const referrer = localStorage.getItem("referrer");

                if (!lp) localStorage.setItem("landed", document.location.href);
                if (!referrer && document.referrer != null) localStorage.setItem("referrer", document.referrer);
            },
            async _setUserIPInfo() {
                const info = await this.fetchIPInfoJSON();
                this.$store.commit("UPDATE_IPINFO", info);
            },

            async _redirect() {
                let referralData = {
                    isReferral: false,
                    navPath: ""
                };
                const isCompletedReferral = JSON.parse(localStorage.getItem('isCompletedReferral')) || false;
                if (!isCompletedReferral) {
                    referralData = await showCustomBuyerExperience();
                }
                const isReferral = referralData.isReferral && this.customer?.referalCode;
                if (!isReferral) {
                    localStorage.setItem('isCompletedReferral', true);
                }
                if (!isReferral && this.$route.path != "/" && this.$route.path != "/login" && this.$route.path != "/passwordless" &&
                    this.$route.path != "/passwordless-cb") {
                    return;
                }
                let savedRoute = localStorage.getItem("route");
                if (isReferral) {
                    savedRoute = referralData.navPath;
                }
                if (this.$route.path !== savedRoute) this.$router.replace(savedRoute || "/vehicles");
            },

            async fetchIPInfoJSON() {
                const response = await fetch('https://ipapi.co/json/');
                const json = await response.json();
                return json;
            },

            async _postCustomerLoginData() {
                try {
                    const sessionUrl = this.$Logger.getSessionURL();
                    const uri = `customers/${this.customer.id}/login-data`;
                    const data = {
                        deviceOS: this.device.os,
                        deviceProduct: this.device.product,
                        deviceManufacturer: this.device.manufacturer,
                        logRocketSessionUrl: sessionUrl
                    };
                    const response = await api.post(uri, data);
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "postCustomerLoginData"
                    }, error);
                }
            },


            async loadPartners() {
                try {
                    const response = await fetch("https://parked.blob.core.windows.net/data-files/partners.json");
                    this.partners = await response.json();
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "loadPartners"
                    }, error);
                }
            },

            async loadCustomer(email, phone) {
                try {
                    // Fetch customer by login e-mail:
                    const encoded_email = encodeURIComponent(email);
                    const encoded_phone = encodeURIComponent(phone);

                    const uri = email ? `customers/by-email/?email=${encoded_email}` :
                        `customers/by-phone/?phone=${encoded_phone}`;
                    const response = await api.get(uri, {
                        requestId: uri
                    });
                    this.$store.commit("UPDATE_CUSTOMER", response.data);

                    this._postCustomerLoginData();
                    this._redirect();
                } catch (error) {
                    if (error.response && error.response.status == 404) {
                        this.$router.push("/register");
                    } else {
                        this.error = error.message;
                        this.$Logger.error({
                            component: this.$options.name,
                            method: "loadCustomer"
                        }, error);
                    }
                }
            }
        },

        created() {
            this.$Logger.track(`app_created`, {
                component: this.$options.name,
                email: this.user && this.user.email || 'N/A'
            });

            //Clear all Notifications on App created:
            this.$store.commit("CLEAR_NOTIFICATIONS");
            //Set Partner:
            const requested = this.$route.query && this.$route.query.sub;
            if (this.partner && !requested) {
                this._setTheme(this.partner)
            } else {
                this._setPartner();
            }
            //Set manifest.json & Meta props:
            this._setDynamicManifest();
            this._setUtmParams();
            this._setTrafficRef();
            this._setUserIPInfo();
        },
        mounted() {
            this.$Logger.track(`app_mounted`, {
                component: this.$options.name,
                email: this.user && this.user.email || 'N/A'
            });
        }
    };
</script>

<style lang="scss">
    @import "assets/app.scss";

    #app.offline {
        filter: grayscale(0.95);
    }

    #app.week-signal {
        filter: grayscale(0.35);
    }
</style>
<template>
  <v-dialog max-width="500" v-model="isOpen">
    <template>
      <v-card
        class="image-viewer-card d-flex flex-column overflow-hidden"
        rounded="lg"
      >
        <v-card-title
          class="preview-modal-title d-flex justify-space-between align-center"
        >
          <div v-if="title" class="text-medium-emphasis ps-2">
            {{ title }} sample
          </div>
          <v-spacer v-else></v-spacer>

          <v-btn icon rounded @click="$emit('onClose')">
            <v-icon>$vuetify.icons.global_close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="image-viewer-content overflow-auto">
          <v-img v-if="docType.includes('image')" :src="imgUrl" max-height="600" contain></v-img>
          <pdf-preview v-else :base64Pdf="imgUrl"></pdf-preview>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import PdfPreview from './PdfPreview.vue';
export default {
  components: { PdfPreview },
  name: 'ImageViewerDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    imgUrl: {
      type: String,
      required: true,
    },
    docType: {
      type: String,
      default: 'image',
    },
  },
};
</script>

<style>
.preview-modal-title {
  padding: 8px 14px !important;
  text-transform: capitalize;
  background: #fff;
  flex: 0 0 52px;
}
.image-viewer-content {
  height: 100%;
  flex: 1;
}
</style>

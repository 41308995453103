<template>
  <v-container class="customization-wrapper">
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <template v-else>
      <DataContributorHeader
        :contributorImg="buyerData?.listLogo"
        :isRoundImg="customer.referalCode.toLowerCase() !== 'lowtor'"
      />
      <EmptyData
        :showBottomButtons="false"
        header1="SERVICE HISTORY IS VALUABLE"
        header2="Keeping your car service records can boost resale value, aid with warranty and insurance claims, and stay on track with car care."
        imgPath="assets/ownli/service_history.png"
        :benefits="[
          'Service Check-In Rewards.',
          'Automatic Service Reminders.',
          'Paperless Maintenance Records.',
        ]"
      />
      <v-spacer></v-spacer>
      <v-btn
        x-large
        color="primary"
        @click="onContinue()"
        :disabled="isSubmitting"
        >Sync My Service Check-In</v-btn
      >
      <v-btn
        text
        color="primary"
        class="mt-4 text-center text-decoration-underline"
        :disabled="isSubmitting"
        @click="isOpenDialog = true"
        >I'D RATHER NOT</v-btn
      >
    </template>
    <ConfirmationDialog
      :isOpen="isOpenDialog"
      title="Are your sure?"
      bodyText="You won’t earn from your service history with Low Tor."
      cancelButton="Cancel"
      confirmButton="Yes, I am sure"
      confirmButtonColor="primary"
      cancelButtonColor="grey darken-2"
      @onCancel="isOpenDialog = false"
      @onConfirm="declineSync"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import StepOneSVG from '@/components/onboarding/StepOneSVG';
import api from '@/services/api-service';
import BrandLogo from '@/components/appshell/BrandLogo.vue';
import { updateTOSAnswer } from '@/services/app.service';
import DataContributorHeader from '@/components/onboarding/DataContributorHeader.vue';
import EmptyData from '@/components/global/EmptyData.vue';
import ConfirmationDialog from '@/components/global/ConfirmationDialog.vue';

export default {
  name: 'DataContributorOnboardingStep2',
  components: {
    StepOneSVG,
    BrandLogo,
    DataContributorHeader,
    EmptyData,
    ConfirmationDialog,
  },
  data() {
    return {
      loading: true,
      buyerData: null,
      isSubmitting: false,
      isOpenDialog: false,
    };
  },
  computed: {
    ...mapGetters(['customer']),
  },
  mounted() {
    this.loadDetails();
  },
  methods: {
    async loadDetails() {
      try {
        const response = await api.get(
          `data-buyers/${this.customer.contributorId}`
        );
        console.log(response);
        this.buyerData = response.data;
        console.log(this.customer);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async onContinue(answers = 1) {
      try {
        this.isSubmitting = true;
        await updateTOSAnswer(this.customer.id, this.buyerData.id, answers);
        localStorage.setItem('isCompletedReferral', true);
        this.$router.replace(answers === 2 ? '/home-card' : '/data-contributor-3');
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: 'onContinue',
          },
          error
        );
      } finally {
        this.isSubmitting = false;
      }
    },
    declineSync() {
      this.isOpenDialog = false;
      this.onContinue(2);
    },
  },
};
</script>

<style lang="scss">
.customization-wrapper {
  max-width: 600px;
  .empty-data-container {
    margin-top: 0px;
    margin-bottom: 24px;
    .empty-title {
      font-size: 26px;
    }
  }
}
</style>

<template>
  <v-stepper v-model="step" fill-height flat tile class="pb-15">
    <v-form v-model="valid" ref="form">
      <v-stepper-items>
        <v-stepper-content class="steper-content" step="1">
          <v-card fill-height flat tile color="transparent" class="card-flex">
            <v-card-title class="pa-0">
              <StepOneSVG
                :color="$vuetify.theme.currentTheme.primary"
                :accent="$vuetify.theme.currentTheme.accent"
              />
            </v-card-title>
            <v-card-text class="pt-5">
              <h2>First things first</h2>
              <p>To get started, tell us a little bit about yourself.</p>
            </v-card-text>
            <v-card-text>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="firstname"
                    :rules="nameRules"
                    required
                    label="First name"
                    append-icon="$vuetify.icons.global_required"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="lastname"
                    :rules="nameRules"
                    required
                    label="Last name"
                    append-icon="$vuetify.icons.global_required"
                  />
                </v-col>

                <v-col cols="12">
                  <MobilePhoneInput
                    outlined
                    clearable
                    v-model="phone"
                    return-international
                    @country="countryChanged"
                    :country-code="countryCode"
                    :allow-country-change="!partner"
                    :hint="`Number will be saved as ${phone || ' - '}`"
                    label="Mobile Number"
                    v-if="!isPhoneProvider"
                  />

                  <v-text-field
                    outlined
                    label="Mobile Number"
                    v-model="phone"
                    readonly
                    v-else
                  >
                    <template v-slot:prepend-inner>
                      <v-avatar :size="24" tile class="mr-5">
                        <v-img :src="countryFlag" />
                      </v-avatar>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" xl="4">
                  <v-text-field
                    outlined
                    v-model="email"
                    :rules="emailRules"
                    required
                    label="Primary e-mail used for notifications"
                    type="email"
                    append-icon="$vuetify.icons.global_required"
                  />
                </v-col>
              </v-row>

              <!-- <div class="mt-10 text-center">Need any help?<br />
                                <a target="_blank" href="https://joinparked.zendesk.com/hc/en-us/requests/new">Contact
                                    us</a></div> -->
              <v-row dense>
                <v-col cols="12" xl="4" v-if="countryCode == 'US'">
                  <UsStatesPicker
                    outlined
                    v-model="state"
                    :rules="requiredRule"
                    required
                    clearable
                    append-icon="$vuetify.icons.global_required"
                    label="State"
                    placeholder="Start typing to search"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" xl="4">
                  <v-text-field
                    v-model="referalCode"
                    outlined
                    persistent-hint
                    label="Referral Code"
                    hint="Have a referral code? Add is here."
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" xl="4">
                  <v-checkbox v-model="termsAndCondition">
                    <template v-slot:label>
                      <div @click.stop="">
                        I agree to Ownli's
                        <a
                          target="_blank"
                          href="https://support.ownli.co/hc/en-us/articles/360011275958-Terms-of-Use"
                          @click.prevent="true"
                          >Terms Of Use</a
                        >
                        &
                        <a
                          target="_blank"
                          href="https://support.ownli.co/hc/en-us/articles/360011276138-Privacy-policy"
                          @click.prevent="true"
                          >Privacy Policy</a
                        >
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-btn text @click="signOut" :disabled="loading">
                <v-icon>mdi-chevron-left</v-icon>
                prev
              </v-btn>
              <v-spacer />
              <!-- <v-radio-group v-model="step" row dense>
                                <v-radio :value="1"></v-radio>
                                <v-radio :value="2"></v-radio>
                            </v-radio-group> -->
              <v-spacer />
              <v-btn
                text
                @click="register"
                :disabled="!valid || loading || !termsAndCondition"
                :loading="loading"
              >
                Create Account
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- <v-stepper-content step="2">
          <navbar-top-with-back-btn
            :backButton="false"
            :headerTitle="'Welcome ' + firstname"
          ></navbar-top-with-back-btn>
          <v-container>
            <p class="text-body-1 mt-4">
              We help you capture, certify and sell your data directly to
              businesses that want to work with you.To get things started,
              select at least 3 data points we can help you sell.
            </p>
            <v-row>
              <v-col
                v-for="dataPoint in dataPoints"
                :key="dataPoint.type"
                cols="6"
                md="4"
              >
                <v-card
                  :ripple="false"
                  @click="toggleSelection(dataPoint)"
                  class="data-card-static mb-3 pt-4"
                  elevation="2"
                  :class="{ selected: dataPoint.isSelected }"
                >
                  <v-checkbox
                    class="checkbox mt-0"
                    v-model="dataPoint.isSelected"
                    hide-details
                    @click.stop
                  ></v-checkbox>
                  <v-img
                    :src="dataPoint.assetPath"
                    :alt="dataPoint.displayName"
                    width="80"
                    height="80"
                    class="mx-auto"
                  ></v-img>
                  <v-card-subtitle class="text-center">{{
                    dataPoint.displayName
                  }}</v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-footer fixed class="py-4">
            <v-btn
              :disabled="validateDatapointSelection"
              color="primary"
              class="mx-auto"
              @click="submitSurvey"
              :loading="loading"
              >Finish</v-btn
            >
          </v-footer>
        </v-stepper-content> -->

        <v-stepper-content class="steper-content" step="2">
          <v-card fill-height flat tile color="transparent" class="card-flex">
            <v-card-title class="pa-0 justify-center">
              <v-img src="assets/ownli/registration_banner.png" />
              <v-img
                class="transparent-logo"
                src="assets/ownli/ownli_logo_white.svg"
              />
            </v-card-title>
            <v-container class="pa-4">
              <h1>Hello {{ firstname }}</h1>
              <p>You're all set to start earning from your data.</p>
              <v-list-item class="pa-0 mt-8">
                <v-list-item-action class="mr-4">
                  <v-icon color="primary"
                    >$vuetify.icons.pages_home_card</v-icon
                  >
                </v-list-item-action>

                <v-list-item-content class="text--dark text-uppercase">
                  Data
                </v-list-item-content>
              </v-list-item>

              <p class="mb-6">Select which types data to add.</p>

              <v-list-item class="pa-0">
                <v-list-item-action class="mr-4">
                  <v-icon color="success"
                    >$vuetify.icons.wallet</v-icon
                  >
                </v-list-item-action>

                <v-list-item-content class="text--dark text-uppercase">
                  Wallet
                </v-list-item-content>
              </v-list-item>

              <p class="mb-6">Claim your rewards here after your data is verified.</p>

              <v-list-item class="pa-0">
                <v-list-item-action class="mr-4">
                  <v-icon color="primary"
                    >$vuetify.icons.pages_market_place</v-icon
                  >
                </v-list-item-action>

                <v-list-item-content class="text--dark text-uppercase">
                  Marketplace
                </v-list-item-content>
              </v-list-item>

              <p class="mb-6">Visit here to see how much you can earn.</p>

            </v-container>
            <v-spacer />
            <v-card-actions class="px-5">
              <v-btn
                large
                block
                color="primary"
                to="/home-card"
                :loading="loading"
                >Add data & earn</v-btn
              >
            </v-card-actions>
            <v-card-actions class="px-5">
              <v-btn
                large
                block
                color="primary"
                to="/marketplace"
                :loading="loading"
                >Your data marketplace</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-form>
    <v-dialog v-model="referalDialog" persistent max-width="350px">
        <v-card class="card-center">
          <v-card-title class="text-h5"> Invalid Referral Code </v-card-title>

          <v-card-text>
            The referral code provided is invalid.
          </v-card-text>

          <v-card-text>
            <v-btn
              block
              large
              color="primary"
              class="mb-2"
              @click="referalDialog = false"
            >
              Try again
            </v-btn>
            <v-btn
              block
              large
              color="primary"
              outlined
              @click="submitWithOutReferal"
            >
              Continue
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
  </v-stepper>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuth, signOut } from "firebase/auth";
import api from "@/services/api-service";
import InsuranceProviderPicker from "@/components/customer/InsuranceProviderPicker";
import UsStatesPicker from "@/components/customer/UsStatesPicker";
import MobilePhoneInput from "@/components/global/MobilePhoneInput";
import StepOneSVG from "@/components/register/StepOneSVG";
import StepTowSVG from "@/components/register/StepTowSVG";
import StepThreeSVG from "@/components/register/StepThreeSVG";
import NavbarTopWithBackBtn from "../components/appshell/NavbarTopWithBackBtn.vue";
import { KYNAT_REFERRAL_CODE } from "../data/constants";

export default {
  name: "Register",

  components: {
    MobilePhoneInput,
    StepOneSVG,
    StepTowSVG,
    StepThreeSVG,

    InsuranceProviderPicker,
    UsStatesPicker,
    NavbarTopWithBackBtn,
  },

  computed: {
    ...mapGetters(["ipinfo", "user", "partner", "device", "customer"]),
    validateDatapointSelection() {
      const selected = this.dataPoints.filter((e) => e.isSelected).length;
      return selected < 3;
    },
    nameFirst() {
      if (!this.user || !this.user.name) {
        return null;
      }
      let split = this.user.name.split(" ");
      return split[0];
    },
    nameLast() {
      if (!this.user || !this.user.name) {
        return null;
      }
      let split = this.user.name.split(" ");
      return split[1];
    },

    authProvider() {
      return this.user && this.user.provider;
    },
    isPhoneProvider() {
      return this.authProvider == "phone";
    },
    isAppleAuthProvider() {
      return this.authProvider == "apple.com";
    },

    countryFlag() {
      const code = this.countryCode && this.countryCode.toLowerCase();
      return `https://flagcdn.com/${code}.svg`;
    },

    maxNumberOfVehiclesOnPolicy() {
      if (!this.insurance) {
        return [
          {
            text: "1",
            value: 1,
          },
        ];
      }

      let items = [];
      for (
        let index = 1;
        index <= this.insurance.maxVehiclesPerPolicy;
        index++
      ) {
        items.push({
          text: `${index}`,
          value: index,
        });
      }

      return items;
    },

    hasDiscounts() {
      return this.customer && this.customer.policy.hasDiscounts;
    },
  },

  watch: {
    insurance(v, ov) {
      if (v && v.maxVehiclesPerPolicy == 1) {
        this.numberOfVehiclesOnPolicy = 1;
      }
    },
  },

  data() {
    return {
      step: 1,
      loading: false,
      valid: false,
      exists: false,
      insurance: this.partner,
      state: null,
      numberOfVehiclesOnPolicy: null,
      termsAndCondition: false,

      requiredRule: [(v) => !!v || "Field is required"],

      policyNumber: null,

      firstname: null,
      lastname: null,
      referalDialog: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 30) || "Name must be less than 30 characters",
      ],

      email: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => (v && /.+@.+/.test(v)) || "Please enter a valid E-mail",
      ],

      country: "United States",
      countryCode: "US",
      dialCode: "1",
      phone: null,
      referalCode: null,
      dataPoints: [
        {
          surveyId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
          type: "VEHICLE",
          assetPath: "assets/buyers/svg/datapoint_vehicle.svg",
          displayName: "Vehicle",
          isSelected: false,
        },
        {
          surveyId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
          type: "HOME",
          assetPath: "assets/buyers/svg/datapoint_home.svg",
          displayName: "Home",
          isSelected: false,
        },
        {
          surveyId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
          type: "APARTMENT",
          assetPath: "assets/buyers/svg/datapoint_apartment.svg",
          displayName: "Apartment",
          isSelected: false,
        },
        {
          surveyId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
          type: "PET",
          assetPath: "assets/buyers/svg/datapoint_pet.svg",
          displayName: "Pet",
          isSelected: false,
        },
        {
          surveyId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
          type: "CONNECTED_WATCH",
          assetPath: "assets/buyers/svg/datapoint_connected_watch.svg",
          displayName: "Connected Watch",
          isSelected: false,
        },
        {
          surveyId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
          type: "CONNECTED_HOME_DEVICE",
          assetPath: "assets/buyers/svg/datapoint_connected_home_device.svg",
          displayName: "Connected Home Device",
          isSelected: false,
        },
        {
          surveyId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
          type: "STREAMING_SERVICE",
          assetPath: "assets/buyers/svg/datapoint_streaming_service.svg",
          displayName: "Streaming Service",
          isSelected: false,
        },
        {
          surveyId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
          type: "SOCIAL_NETWORK",
          assetPath: "assets/buyers/svg/datapoint_social_network.svg",
          displayName: "Social Network",
          isSelected: false,
        },
      ],
      surveyTemplates: [],
      surveyTemplate: null,
    };
  },

  methods: {
    toggleSelection(dataPoint) {
      dataPoint.isSelected = !dataPoint.isSelected;
    },
    countryChanged(obj) {
      if (!obj || this.partner) {
        return;
      }

      this.country = obj.name;
      this.countryCode = obj.code;
      this.dialCode = obj.dial;
      this.insurance = null;
    },

    fillUserDetails() {
      this.firstname = this.nameFirst;
      this.lastname = this.nameLast;
      this.email = this.user.email;
      this.phone = this.user.phone;

      if (this.user.phone) {
        if (this.user.phone.startsWith("+1")) {
          this.countryCode = "US";
          this.dialCode = "1";
          // this.phone = this.user.phone.substring(2);
        }
        if (this.user.phone.startsWith("+44")) {
          this.countryCode = "GB";
          this.dialCode = "44";
          // this.phone = this.user.phone.substring(3);
        }
        if (this.user.phone.startsWith("+81")) {
          this.countryCode = "JP";
          this.dialCode = "81";
          // this.phone = this.user.phone.substring(3);
        }
        if (this.user.phone.startsWith("+972")) {
          this.countryCode = "IL";
          this.dialCode = "972";
          // this.phone = this.user.phone.substring(4);
        }
      }
    },
    fillPartnerDetails() {
      if (!this.partner) {
        return;
      }
      this.insurance = this.partner;
      this.country = this.partner.country;
      this.countryCode = this.partner.countryCode;
      this.dialCode = this.partner.countryDialCode;
      // this.onlyCountries = [this.partner.countryCode];

      if (this.partner.countryCode != "US") {
        this.state = this.partner.country;
      }
    },

    async register() {
      if (!this.$refs.form.validate()) {
        return null;
      }

      try {
        if(this.referalCode) {
          this.loading = true;
          const response = await api.get(`customers/match-share-code/${this.referalCode}`);
          console.log(response);
          if(!response?.data) {
            this.referalDialog = true;
            this.loading = false;
          } else {
            await this.updateCustomer(this.referalCode);
          }
        }

      } catch (error) {
        if (error.response && error.response.status == 409) {
          this.$store.commit("ADD_NOTIFICATION", {
            text: "This email or phone number is already associated with an existing account.",
            timeout: 8000,
            multiLine: true,
          });
          this.exists = true;
        }
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "register",
          },
          error
        );
      } finally {
        this.loading = false;
      }
    },
    async submitWithOutReferal(){
      this.referalCode = null;
      await this.updateCustomer();
      this.loading = false;
    },
    async updateCustomer(){
      this.referalDialog = false;
      const sessionUrl = this.$Logger.getSessionURL();

      this.loading = true;
      const request = {
        nameFirst: this.firstname,
        nameLast: this.lastname,
        loginEmail: this.user.email || this.email,
        contact: {
          email: this.email,
          phone: this.phone,
        },
        trafficReferral: {
          source: localStorage && localStorage.getItem("utm_source"),
          medium: localStorage && localStorage.getItem("utm_medium"),
          campaign: localStorage && localStorage.getItem("utm_campaign"),
          registeredWithIP: this.ipinfo && this.ipinfo.ip,
          registeredWithDevice: this.device && this.device.product,
        },
        lastLoginData: {
          deviceOS: this.device && this.device.os,
          deviceProduct: this.device && this.device.product,
          deviceManufacturer: this.device && this.device.manufacturer,
          logRocketSessionUrl: sessionUrl,
        },
        affiliateId: localStorage && localStorage.getItem("affiliate"),
        insuranceCompanyId: "ICOM_9jHE99cWpfK0",
        insurancePolicyState:
          this.countryCode == "US" ? this.state : this.insurance.country,
        //   numberOfVehiclesOnPolicy: this.numberOfVehiclesOnPolicy,
        referalCode: this.referalCode,
      };
      const response = await api.post("customers", request);
        this.$store.commit("UPDATE_CUSTOMER", response.data);
        const result = await this.getSurveyTemplates();
        if(this.referalCode) {
          if (this.referalCode?.toUpperCase() === KYNAT_REFERRAL_CODE) {
            this.$router.replace('/kynat-properties-referral');
          } else {
            this.$router.replace("/data-contributor-1")
          }
        } else {
          this.nextStep();
        }
    },
    async signOut() {
      try {
        const auth = getAuth();
        let result = await signOut(auth);
        // console.log("signOut:", result);
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "signOut",
          },
          error
        );
        // console.log("signOut.error:", error);
      }
    },
    async lead() {
      try {
        const sessionUrl = this.$Logger.getSessionURL();

        this.loading = true;
        const request = {
          AuthProvider: this.user.provider,
          Name: `${this.firstname || ""} ${this.lastname || ""}`,
          Email: this.email,
          Phone: this.phone,

          ClientIP: this.ipinfo && this.ipinfo.ip,
          ClientCountry: this.ipinfo && this.ipinfo.country_name,
          ClientCity: this.ipinfo && this.ipinfo.city,

          DeviceOS: this.device && this.device.os,
          DeviceModel: this.device && this.device.product,
          DeviceMake: this.device && this.device.manufacturer,

          LogRocketSessionUrl: sessionUrl,
          ReferrerUrl: localStorage && localStorage.getItem("referrer"),
          LandingUrl: localStorage && localStorage.getItem("landed"),

          AffiliateId: localStorage && localStorage.getItem("affiliate"),
        };

        // const response = await api.post("/leads", request);
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "lead",
          },
          error
        );
      } finally {
        this.loading = false;
      }
    },

    nextStep() {
      if (!this.$refs.form.validate()) {
        return null;
      }
      this.step = this.step + 1;
      this.$Logger.track(`registration_flow`, {
        component: this.$options.name,
        step: this.step,
      });
    },

    async getSurveyTemplates() {
      const url = "survey-templates";
      const response = await api.get(url);
      console.log("get survey template respobse", response.data);
      if (response?.data) {
        this.surveyTemplates = response.data;
        this.surveyTemplate = this.surveyTemplates.find(
          (e) => e.title == "Onboarding Survey"
        );
      }
      return response;
    },

    async submitSurvey() {
      this.loading = true;
      const selectedDatapoint = this.dataPoints.filter((e) => e.isSelected);
      const apiSurveys = this.surveyTemplate.questions[0].answers;
      const answers = [];
      selectedDatapoint.forEach((e) => {
        const find = apiSurveys.find((t) => t.text == e.type);
        if (find) {
          answers.push({
            surveyTemplateQuestionId: "SURVEY_ONBOARDING_QUESTIONNAIRE_Q1",
            surveyTemplateAnswerId: find.id,
            score: 1,
          });
        }
      });
      const params = {
        customerId: this.customer.id,
        surveyTemplateId: this.surveyTemplate.id,
        answers: answers,
        totolScore: 5,
      };
      const url = "surveys/onboarding";
      const response = await api.post(url,params);
      console.log('registration successfully',response);
      if(response.data){
        this.nextStep();
      }
      this.loading = false;
    },
  },

  mounted() {
    //Set Globalization by IP:
    if (this.ipinfo) {
      this.country = this.ipinfo.country_name;
      this.countryCode = this.ipinfo.country_code;
      this.dialCode = this.ipinfo.country_calling_code.replace("+", "");
    }

    //Fill User & Partner details:
    this.fillUserDetails();
    this.fillPartnerDetails();

    //Create a Lead entity prior registration:
    // this.lead();

    this.$Logger.track(`registration_flow`, {
      component: this.$options.name,
      step: this.step,
    });
  },
};
</script>

<style scoped>
form {
  background: none !important;
  height: 100% !important;
}
.data-card-static {
  height: 100%;
}

.v-card__title > svg {
  width: 100%;
  height: auto;
}

.v-stepper,
.v-stepper__items,
.v-stepper__content {
  background: none !important;
  height: 100% !important;
  padding: 0;
}
.checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
}
.transparent-logo {
  position: absolute;
  margin: auto;
  width: 6vw;
}
</style>